// import forge from "node-forge";
import { parseISO, compareDesc } from "date-fns";
import { checkDomainConfiguration } from "./api";

// const publicKeyBase64 = process.env.REACT_APP_CIRCLECI_PUBLIC_KEY_BASE64;
// if (!publicKeyBase64) {
//   throw new Error("Public key is not defined in environment variables");
// }
// const publicKeyPem = atob(publicKeyBase64);
// const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

export function isProjectInfoUrl(
  info: ProjectInfoUrl | ProjectInfoGithub | ProjectInfoManual,
): info is ProjectInfoUrl {
  return (info as ProjectInfoUrl).url !== undefined;
}

export function isProjectInfoGithub(
  info: ProjectInfoUrl | ProjectInfoGithub | ProjectInfoManual,
): info is ProjectInfoGithub {
  return (info as ProjectInfoGithub).repositoryId !== undefined;
}

export const encryptWithPublicKey = (data: any) => {
  // const encrypted = publicKey.encrypt(data, "RSA-OAEP", {
  //   md: forge.md.sha256.create(),
  //   mgf1: { md: forge.md.sha1.create() },
  // });
  // return forge.util.encode64(encrypted);
  return data;
};

export function getRecentPublishTransaction(transactions?: Transaction[]) {
  if (!transactions?.length) return;
  const publishTransactions = transactions.filter(
    (transaction) => transaction.transactionType === "publishProject",
  );

  publishTransactions.sort((a, b) =>
    compareDesc(parseISO(a.timestamp), parseISO(b.timestamp)),
  );

  return publishTransactions[0];
}

export function capitalize(text: string) {
  let sentences = text.split(/([.!?]\s+)/);
  sentences = sentences.map((sentence) => {
    if (sentence.trim() === "") return sentence;
    return sentence.trim().charAt(0).toUpperCase() + sentence.trim().slice(1);
  });
  return sentences.join("");
}

export function setStatusColor(status: string) {
  switch (status) {
    case "pending":
      return "orange";
    case "failure":
      return "red";
    default:
      return "green";
  }
}

const setFavicon = (isDarkMode: boolean) => {
  const favicon = document.getElementById("favicon") as HTMLLinkElement;
  if (isDarkMode && favicon) {
    favicon.href = process.env.PUBLIC_URL + "/earthfast-logo-dark.ico";
  } else if (favicon) {
    favicon.href = process.env.PUBLIC_URL + "/earthfast-logo.ico";
  }
};

const updateFavicon = () => {
  const isDarkMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;
  setFavicon(isDarkMode);
};

export const initFavicon = () => {
  updateFavicon();
  window
    .matchMedia("(prefers-color-scheme: dark)")
    .addEventListener("change", updateFavicon);
};

export const formatNodeId = (id: string) => {
  if (id.length > 12) {
    return `${id.slice(0, 6)}...${id.slice(-5)}`;
  }
  return id;
};

export const verifyDomainDNS = async (domain: string, type: "A" | "CNAME") => {
  try {
    const result = await checkDomainConfiguration(domain, type);
    return result;
  } catch (error) {
    console.error("Failed to check domain configuration:", error);
    return { valid: false, configured: false, type };
  }
};

export const isApexDomain = (domain: string) => {
  return domain.split(".").length === 2;
};

export const getDnsInstructions = (domain: string) => {
  if (isApexDomain(domain)) {
    return {
      type: "A Record",
      value: process.env.REACT_APP_A_RECORD_IP || "",
      host: "@",
      instructions:
        "Set the following record on your DNS provider to continue:",
      note: "Depending on your provider, it might take some time for the DNS records to apply.",
    };
  }
  return {
    type: "CNAME Record",
    value: process.env.REACT_APP_CNAME_RECORD_VALUE || "",
    host: domain.split(".")[0],
    instructions: "Set the following record on your DNS provider to continue:",
    note: "Depending on your provider, it might take some time for the DNS records to apply.",
  };
};

export function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export const determineFrameworkType = (
  projectData: ProjectObjectToCreate,
  isFrameworkEnabled: boolean,
): string => {
  if (!isFrameworkEnabled) {
    return "static";
  }
  return projectData.projectInfo.frameworkUIDisplay || "static";
};

export const isFileInRoot = (filePath: string, fileName: string): boolean => {
  const isInRootFolder = !filePath.includes("/") && filePath === fileName;
  const parts = filePath.split("/");
  return isInRootFolder || (parts.length === 2 && parts[1] === fileName);
};

interface FileWithPath {
  path: string;
  file: File;
}

export const normalizeFilePath = (file: File): FileWithPath => {
  const path = file.webkitRelativePath || (file as any).path;
  return {
    path: path.startsWith("/") ? path.substring(1) : path,
    file,
  };
};

export const checkForIndexHtml = (files: FileWithPath[]): boolean => {
  return files.some(({ path }) => isFileInRoot(path, "index.html"));
};

interface PackageJson {
  dependencies?: {
    next?: string;
  };
}

export const checkForNextJs = async (
  files: FileWithPath[],
): Promise<boolean> => {
  const packageJsonFile = files.find(({ path }) =>
    isFileInRoot(path, "package.json"),
  );
  const hasServerJs = files.some(({ path }) => isFileInRoot(path, "server.js"));

  if (!packageJsonFile || !hasServerJs) return false;

  try {
    const content = await packageJsonFile.file.text();
    const packageJson = JSON.parse(content) as PackageJson;
    return packageJson.dependencies?.next !== undefined;
  } catch (error) {
    console.error("Error parsing package.json:", error);
    return false;
  }
};
