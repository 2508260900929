import {
  Box,
  VStack,
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Center,
  Text,
  Link,
  Heading,
  Divider,
  useBreakpointValue,
} from "@chakra-ui/react";
import FolderUpload from "../../components/FolderUpload";
import FormHelpPopover from "../../components/FormHelpPopover";
import { capitalize } from "../../util";

interface FileDeploymentProps {
  uploadMethod: string;
  bundleUrl: string;
  checksum: string;
  projectName?: string;
  isDeploying: boolean;
  onSetFolderFiles: (files: any[]) => void;
  onSetBundleUrl: (url: string) => void;
  onSetUploadMethod: (uploadMethod: string) => void;
  onSetChecksum: (checksum: string) => void;
  handleFileSubmission: () => void;
  goBack: () => void;
}

function FileDeployment({
  uploadMethod,
  bundleUrl,
  checksum,
  projectName = "",
  isDeploying,
  onSetFolderFiles,
  onSetBundleUrl,
  onSetChecksum,
  onSetUploadMethod,
  handleFileSubmission,
  goBack,
}: FileDeploymentProps) {
  const dividerOrientation = useBreakpointValue<"horizontal" | "vertical">({
    base: "horizontal",
    md: "vertical",
  });

  return (
    <Box
      width="full"
      maxWidth={{ base: "95%", md: "80%" }}
      margin="0 auto"
      padding={{ base: 3, md: 5 }}
    >
      <Flex
        justifyContent="space-between"
        alignItems={{ base: "flex-start", md: "center" }}
        flexDirection={{ base: "column", md: "row" }}
        mb={{ base: 4, md: 8 }}
        gap={4}
      >
        <Heading fontSize={{ base: 24, md: 30 }} fontWeight="semibold">
          Deployment
          <Text
            as="span"
            fontSize={{ base: 16, md: 18 }}
            display="block"
            color="textColor"
            fontWeight={400}
            minHeight={21.6}
          >
            {capitalize(projectName)}
          </Text>
        </Heading>
        <Button
          onClick={goBack}
          variant="outline"
          fontWeight={500}
          width={{ base: "full", md: "auto" }}
        >
          Back
        </Button>
      </Flex>
      <Box bg="white" p={{ base: 4, md: 10 }} borderWidth={1} borderRadius="lg">
        <Flex
          justify="space-around"
          mb={4}
          overflowX={{ base: "auto", md: "visible" }}
        >
          <Center
            borderWidth={1}
            borderColor="rgba(0, 0, 0, 0.24)"
            p={3}
            borderRadius={8}
            minWidth={{ base: "max-content", md: "auto" }}
            width={{ base: "95%", md: "auto" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Button
              colorScheme="teal"
              borderRadius={0}
              variant="link"
              color={uploadMethod === "file" ? "primary" : "#667085"}
              opacity={uploadMethod === "file" ? 1 : 0.7}
              fontWeight={uploadMethod === "file" ? "semibold" : "normal"}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                },
              }}
              onClick={() => onSetUploadMethod("file")}
              width={{ base: "100%", md: "auto" }}
              mb={{ base: 0, md: 0 }}
            >
              Upload Assets Folder
            </Button>
            <Divider
              orientation={dividerOrientation}
              height={{ base: "1px", md: "25px" }}
              width={{ base: "100%", md: "auto" }}
              marginX={{ base: 0, md: "12px" }}
              marginY={{ base: "8px", md: 0 }}
              bg="rgba(0, 0, 0, 0.24)"
            />
            <Button
              colorScheme="teal"
              borderRadius={0}
              variant="link"
              color={uploadMethod === "url" ? "primary" : "#667085"}
              opacity={uploadMethod === "url" ? 1 : 0.7}
              fontWeight={uploadMethod === "url" ? "semibold" : "normal"}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                },
              }}
              onClick={() => onSetUploadMethod("url")}
              width={{ base: "100%", md: "auto" }}
              mb={{ base: 0, md: 0 }}
            >
              Upload Earthfast Bundle URL
            </Button>
          </Center>
        </Flex>
        <VStack spacing={{ base: 3, md: 4 }} align="stretch" width="full">
          {uploadMethod === "file" ? (
            <Box width="full">
              <FolderUpload
                onFilesSelected={onSetFolderFiles}
                isDisabled={isDeploying}
              />
            </Box>
          ) : uploadMethod === "url" ? (
            <>
              <Flex
                justify="center"
                mb={4}
                px={{ base: 2, md: 0 }}
                textAlign={{ base: "center", md: "left" }}
              >
                <Text fontSize={{ base: "sm", md: "md" }}>
                  {"Use this page only if you built your site with the "}
                  <Link
                    color="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/earthfast/armada-cli?tab=readme-ov-file#armada-cli"
                  >
                    Earthfast CLI
                  </Link>
                  {"."}
                </Text>
              </Flex>
              <FormControl>
                <FormLabel
                  display={"inline-block"}
                  fontSize={{ base: "sm", md: "md" }}
                >
                  Bundle URL
                </FormLabel>
                <FormHelpPopover text="Must be a publicly accessible URL containing your bundle, as generated by the Earthfast CLI (`armada-cli bundle create`)"></FormHelpPopover>
                <Input
                  value={bundleUrl}
                  onChange={(e) => onSetBundleUrl(e.target.value)}
                  placeholder="Enter bundle URL"
                  disabled={isDeploying}
                  size={{ base: "sm", md: "md" }}
                />
              </FormControl>
              <FormControl>
                <FormLabel
                  display={"inline-block"}
                  fontSize={{ base: "sm", md: "md" }}
                >
                  Bundle Checksum
                </FormLabel>
                <FormHelpPopover text="The checksum generated by the Earthfast CLI (`armada-cli bundle checksum`)"></FormHelpPopover>
                <Input
                  value={checksum}
                  onChange={(e) => onSetChecksum(e.target.value)}
                  placeholder="Enter checksum"
                  disabled={isDeploying}
                  size={{ base: "sm", md: "md" }}
                />
              </FormControl>
            </>
          ) : null}
          <Button
            colorScheme="blue"
            onClick={handleFileSubmission}
            width={{ base: "full", md: "40%" }}
            maxWidth={531}
            minWidth={{ base: "auto", md: 200 }}
            isLoading={isDeploying}
            alignSelf="center"
            size={{ base: "sm", md: "md" }}
          >
            Create Build
          </Button>
        </VStack>
      </Box>
    </Box>
  );
}

export default FileDeployment;
