import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Text, List, ListItem, useToast } from "@chakra-ui/react";
import { useFeatureFlags } from "../context/featureFlagsContext";
import { normalizeFilePath, checkForIndexHtml, checkForNextJs } from "../util";

interface FolderUploadProps {
  onFilesSelected: (files: any[]) => void;
  isDisabled?: boolean;
}

const FolderUpload: React.FC<FolderUploadProps> = ({
  onFilesSelected,
  isDisabled,
}) => {
  const [files, setFiles] = useState<any[]>([]);
  const toast = useToast();
  const { featureFlags } = useFeatureFlags();
  const isFrameworkEnabled = featureFlags?.IS_FRAMEWORK_ENABLED;

  const onDrop = (acceptedFiles: File[]) => {
    const folderFiles = acceptedFiles.map(normalizeFilePath);

    const validateFolder = async () => {
      const hasIndexHtml = checkForIndexHtml(folderFiles);
      if (hasIndexHtml) return true;

      if (!isFrameworkEnabled) return false;

      return await checkForNextJs(folderFiles);
    };

    validateFolder().then((isValid) => {
      if (isValid) {
        setFiles(folderFiles);
        onFilesSelected(folderFiles);
      } else {
        setFiles([]);
        onFilesSelected([]);
        toast({
          title: "Folder Validation Failed",
          description: "Could not determine if this is frontend project.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: false,
    noKeyboard: true,
    useFsAccessApi: false,
    disabled: isDisabled,
  });

  return (
    <Box padding={{ base: "10px", md: "20px" }} margin="0 auto">
      <Box
        {...getRootProps()}
        width="100%"
        height={{ base: "20vh", md: "30vh" }}
        p={{ base: 3, md: 5 }}
        borderWidth="2px"
        borderRadius="md"
        borderColor="gray.200"
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        cursor="pointer"
        marginBottom={{ base: "15px", md: "20px" }}
      >
        <input
          /* prettier-ignore */
          // @ts-ignore
          {...getInputProps({ webkitdirectory: "true", directory: "true" })}
        />
        <Box>
          <Text fontSize={{ base: "sm", md: "md" }} mb={2}>
            Click here or drag-and-drop a folder to upload your build assets.
          </Text>
          <Text fontSize={{ base: "xs", md: "sm" }}>
            {isFrameworkEnabled ? (
              <>
                The folder must contain index.html or <br />
                package.json (Next.js dependency) and server.js at the root
                level.
              </>
            ) : (
              <>The folder must contain index.html at the root level.</>
            )}
          </Text>
        </Box>
      </Box>
      {files.length > 0 && (
        <List
          padding="0"
          maxHeight={{ base: "25vh", md: "30vh" }}
          width="100%"
          overflow="scroll"
        >
          {files.map(({ path }) => (
            <ListItem key={path} marginBottom="5px">
              <Box
                borderWidth="1px"
                borderRadius="md"
                padding={{ base: "8px", md: "10px" }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text
                  fontSize={{ base: "xs", md: "sm" }}
                  wordBreak="break-all"
                  pr={2}
                >
                  {path}
                </Text>
              </Box>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default FolderUpload;
