import { Link } from "react-router-dom";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Button,
  Flex,
  Badge,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { FiUpload, FiEye, FiTrash, FiLink } from "react-icons/fi";
import { ArrowBackIcon } from "@chakra-ui/icons";
import ProjectInfoDisplay from "../../components/ProjectInfoDisplay";
import ProjectTransactions from "../../components/ProjectTransactions";
import { setStatusColor } from "../../util";
import SettingsTab from "./SettingsTab";
import NodesTab from "./NodesTab";
import CustomDomainsTab from "./CustomDomainsTab";
import Loading from "./Loading";
import BuildsTab from "./BuildsTab";

interface ProjectViewProps {
  createdTransaction?: Transaction;
  lastBuildTransaction?: Transaction;
  isBuilding: boolean;
  projectData: ProjectObject | null;
  isDeleting: boolean;
  isDeleteAlertOpen: boolean;
  isLoadingProject: boolean;
  cancelRef: React.RefObject<HTMLButtonElement>;
  tabIndex: number;
  handleDeleteClick: () => void;
  handleDeleteConfirm: () => void;
  onCloseConfirm: () => void;
  onGoBack: () => void;
  setTabIndex: (index: number) => void;
}

export default function ProjectView({
  createdTransaction,
  lastBuildTransaction,
  isBuilding,
  projectData,
  isDeleting,
  isDeleteAlertOpen,
  cancelRef,
  isLoadingProject,
  tabIndex,
  handleDeleteClick,
  handleDeleteConfirm,
  onCloseConfirm,
  onGoBack,
  setTabIndex,
}: ProjectViewProps) {
  const isUploadProject = projectData?.projectType === "upload";

  return (
    <Box
      px={{ base: 3, sm: 5 }}
      py={{ base: 8, sm: 12 }}
      borderWidth="1px"
      borderRadius="lg"
      width={{ base: "95%", sm: "80%" }}
      marginX="auto"
      bg="white"
      position="relative"
    >
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="ghost"
        onClick={onGoBack}
        position="absolute"
        top={{ base: 2, sm: 4 }}
        left={{ base: 2, sm: 4 }}
        size={{ base: "sm", sm: "md" }}
      >
        Back
      </Button>
      <Box
        width={{ base: "100%", sm: "93%", md: "80%" }}
        minWidth={{ base: "auto", sm: "300px" }}
        marginX="auto"
      >
        {isLoadingProject ? (
          <Loading />
        ) : (
          <>
            <Flex
              justifyContent="space-between"
              alignItems={{ base: "stretch", sm: "flex-start" }}
              mb={6}
              mt={6}
              direction={{ base: "column", sm: "row" }}
              gap={{ base: 4, sm: 0 }}
            >
              <div>
                <Heading
                  fontSize={{ base: 24, sm: 32 }}
                  as="h3"
                  fontWeight="semibold"
                  textAlign="left"
                >
                  {projectData?.name}
                </Heading>
                {projectData && (
                  <>
                    <ProjectInfoDisplay project={projectData} fontSize="md" />
                    {(projectData.customProjectUrls &&
                      projectData.customProjectUrls.length > 0) ||
                    projectData.hostedProjectUrl ? (
                      <Flex
                        alignItems="center"
                        gap={1}
                        justifyContent="left"
                        my={1}
                      >
                        <Box w="18px" h="18px">
                          <FiLink size={16} color="#637381" />
                        </Box>
                        {projectData.customProjectUrls?.map(
                          ({ url }, index) => (
                            <ChakraLink
                              key={index}
                              href={`https://${url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              display="inline-block"
                              width="100%"
                            >
                              <Text
                                fontSize="sm"
                                isTruncated
                                maxW={{
                                  base: "200px",
                                  sm: "300px",
                                  md: "full",
                                }}
                              >
                                {url},
                              </Text>
                            </ChakraLink>
                          ),
                        )}
                        {projectData.hostedProjectUrl && (
                          <ChakraLink
                            href={`https://${projectData.hostedProjectUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            display="inline-block"
                            width="100%"
                          >
                            <Text
                              fontSize="sm"
                              isTruncated
                              maxW={{ base: "200px", sm: "300px", md: "full" }}
                            >
                              {projectData.hostedProjectUrl}
                            </Text>
                          </ChakraLink>
                        )}
                      </Flex>
                    ) : null}
                    {!isBuilding && (
                      <ProjectTransactions
                        createdTransaction={createdTransaction}
                        lastBuildTransaction={lastBuildTransaction}
                        fontSize="md"
                      />
                    )}
                    {projectData?.buildStatus && (
                      <Badge
                        bg={`${setStatusColor(projectData.buildStatus)}.50`}
                        color={`${setStatusColor(projectData.buildStatus)}.600`}
                        textTransform="capitalize"
                        px={2}
                        rounded="400px"
                        fontWeight={500}
                        borderColor={`${setStatusColor(projectData.buildStatus)}.300`}
                        borderWidth="1px"
                        mt={2}
                      >
                        <Flex alignItems="center" gap={1}>
                          <Flex
                            width="6px"
                            height="6px"
                            borderRadius="50%"
                            bg={`${setStatusColor(projectData.buildStatus)}.600`}
                          />
                          {projectData.buildStatus === "pending"
                            ? "Building"
                            : projectData.buildStatus}
                        </Flex>
                      </Badge>
                    )}
                  </>
                )}
              </div>
              {projectData && (
                <Flex
                  direction={{ base: "column", sm: "row" }}
                  gap={{ base: 2, sm: 0 }}
                  width={{ base: "100%", sm: "auto" }}
                >
                  {projectData.projectType === "upload" && (
                    <Button
                      as={Link}
                      to={`/projects/${projectData.id}/deploy`}
                      leftIcon={<FiUpload />}
                      colorScheme="gray"
                      variant="outline"
                      size="sm"
                      mr={{ base: 0, sm: 2 }}
                      width={{ base: "100%", sm: "auto" }}
                    >
                      Upload
                    </Button>
                  )}
                  {projectData.hasSuccessfulBuild &&
                    projectData.hostedProjectUrl && (
                      <Button
                        leftIcon={<FiEye />}
                        colorScheme="gray"
                        as="a"
                        target="_blank"
                        href={`https://${projectData.hostedProjectUrl}`}
                        variant="outline"
                        size="sm"
                        mr={{ base: 0, sm: 2 }}
                        width={{ base: "100%", sm: "auto" }}
                      >
                        View site
                      </Button>
                    )}
                  <Button
                    leftIcon={<FiTrash />}
                    colorScheme="red"
                    variant="outline"
                    size="sm"
                    onClick={handleDeleteClick}
                    isLoading={isDeleting}
                    width={{ base: "100%", sm: "auto" }}
                  >
                    Delete
                  </Button>
                </Flex>
              )}
            </Flex>
            <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
              <TabList>
                {!isUploadProject && (
                  <Tab fontSize={{ base: "sm", sm: "md" }}>Settings</Tab>
                )}
                <Tab fontSize={{ base: "sm", sm: "md" }}>Builds</Tab>
                <Tab fontSize={{ base: "sm", sm: "md" }}>Nodes</Tab>
                <Tab fontSize={{ base: "sm", sm: "md" }}>Custom Domains</Tab>
              </TabList>
              <TabPanels>
                {!isUploadProject && (
                  <TabPanel>
                    <SettingsTab projectData={projectData} />
                  </TabPanel>
                )}
                <TabPanel>
                  <BuildsTab projectData={projectData} />
                </TabPanel>
                <TabPanel>
                  <NodesTab
                    nodes={projectData?.reservedNodes}
                    projectId={projectData?.id}
                  />
                </TabPanel>
                <TabPanel>
                  <CustomDomainsTab
                    hostedProjectUrl={projectData?.hostedProjectUrl}
                    projectId={projectData?.id || 0}
                    customProjectUrls={projectData?.customProjectUrls || []}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        )}
      </Box>

      {/* Keep the existing AlertDialog for project deletion */}
      <AlertDialog
        isOpen={isDeleteAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseConfirm}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Project
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this project? This action cannot
              be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseConfirm}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
