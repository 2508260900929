import { Box, Stack, Skeleton, Flex } from "@chakra-ui/react";

function LoadingBuilds() {
  return (
    <Flex direction="column" align="center" mt={10}>
      <Flex justifyContent="flex-end" alignItems="center" w="100%" mb={4}>
        <Box w={{ base: "20%" }}>
          <Skeleton height="40px" borderRadius="lg" />
        </Box>
      </Flex>
      <Box w="100%">
        <Stack>
          <Skeleton height="90px" borderRadius="lg" />
          <Skeleton height="90px" borderRadius="lg" />
          <Skeleton height="90px" borderRadius="lg" />
          <Skeleton height="90px" borderRadius="lg" />
        </Stack>
      </Box>
    </Flex>
  );
}

export default LoadingBuilds;
