import { useEffect, useState } from "react";
import FileDeployment from "./FileDeployment";
import { useToast } from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import {
  createProjectUpdate,
  getProject,
  triggerBuild,
  uploadFolder,
} from "../../api";

function FileDeploymentContainer() {
  const [projectData, setProjectData] = useState<ProjectObject | null>(null);
  const [folderFiles, setFolderFiles] = useState<any[]>([]);
  const [bundleUrl, setBundleUrl] = useState("");
  const [checksum, setChecksum] = useState("");
  const [isDeploying, setIsDeploying] = useState(false);
  const [uploadMethod, setUploadMethod] = useState("file");
  const toast = useToast();
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (projectId) {
      getProject(Number(projectId)).then((data) => {
        setProjectData(data);
      });
    }
  }, [projectId, toast]);

  async function handleFileSubmission() {
    if (uploadMethod === "file" && folderFiles.length === 0) {
      toast({
        title: "No folder provided",
        description:
          "Please upload a folder containing index.html before submitting.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (uploadMethod === "url" && (!bundleUrl || !checksum)) {
      toast({
        title: "Incomplete input",
        description:
          "Please provide both a bundle URL and checksum before submitting.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsDeploying(true);
      if (uploadMethod === "file") {
        await handleUploadFolder(folderFiles);
      } else if (uploadMethod === "url") {
        await handleCreateProjectUpdate(bundleUrl, checksum);
      }
    } catch (error) {
      toast({
        title: "Error",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeploying(false);
    }
  }

  async function handleUploadFolder(files: any[]) {
    // Check if index.html is at the root level
    const hasIndexHtmlAtRoot = files.some(({ path }) => path === "index.html");

    if (hasIndexHtmlAtRoot) {
      // Move all files into a folder named 'root' to avoid issues with S3 object paths
      files = files.map(({ file, path }) => ({
        file,
        path: `root/${path}`,
      }));
    }

    const formData = new FormData();
    files.forEach(({ file, path }) => formData.append("files", file, path));
    const uploadData = await uploadFolder(formData);
    await triggerBuildWithBundle(uploadData.folderUrl);
  }

  async function triggerBuildWithBundle(url: string) {
    if (!projectId) return;
    await triggerBuild({
      projectId: Number(projectId),
      url,
    });
    toast({
      title: "Build Triggered Successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate(`/projects/${projectId}?tab=builds`);
  }

  async function handleCreateProjectUpdate(
    bundleUrl: string,
    checksum: string,
  ) {
    if (!projectId) return;
    await createProjectUpdate({
      projectId: Number(projectId),
      bundleUrl,
      checksum,
    });
    toast({
      title: "Project Update Created Successfully",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate(`/projects/${projectId}?tab=builds`);
  }

  function goBack() {
    navigate(-1);
  }

  function onSetFolderFiles(files: any[]) {
    setFolderFiles(files);
  }

  function onSetBundleUrl(url: string) {
    setBundleUrl(url);
  }

  function onSetUploadMethod(uploadMethod: string) {
    setUploadMethod(uploadMethod);
  }

  function onSetChecksum(checksum: string) {
    setChecksum(checksum);
  }

  return (
    <FileDeployment
      uploadMethod={uploadMethod}
      bundleUrl={bundleUrl}
      checksum={checksum}
      projectName={projectData?.name}
      isDeploying={isDeploying}
      onSetFolderFiles={onSetFolderFiles}
      onSetBundleUrl={onSetBundleUrl}
      onSetChecksum={onSetChecksum}
      onSetUploadMethod={onSetUploadMethod}
      handleFileSubmission={handleFileSubmission}
      goBack={goBack}
    />
  );
}

export default FileDeploymentContainer;
