import { Box, Flex, Skeleton, Stack } from "@chakra-ui/react";

function Loading() {
  return (
    <Flex direction="column" align="center">
      <Flex alignItems="flex-start" w="100%" my={5} flexDirection="column">
        <Box w={{ base: "80%", sm: "40%" }} mb={2}>
          <Skeleton height="40px" borderRadius="lg" />
        </Box>
        <Box w={{ base: "60%", sm: "20%" }} mb={2}>
          <Skeleton height="30px" borderRadius="lg" />
        </Box>
        <Box w={{ base: "40%", sm: "20%" }} mb={2}>
          <Skeleton height="30px" borderRadius="lg" />
        </Box>
      </Flex>
      <Box w={{ base: "95%", md: "100%" }}>
        <Stack>
          <Skeleton height="90px" borderRadius="lg" />
          <Skeleton height="90px" borderRadius="lg" />
          <Skeleton height="90px" borderRadius="lg" />
          <Skeleton height="90px" borderRadius="lg" />
        </Stack>
      </Box>
    </Flex>
  );
}

export default Loading;
