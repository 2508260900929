import { useMemo } from "react";
import {
  Flex,
  Button,
  IconButton,
  Box,
  Heading,
  Badge,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { FiTrash, FiUpload, FiEye, FiList, FiLink } from "react-icons/fi";
import { getRecentPublishTransaction, setStatusColor } from "../../util";
import ProjectInfoDisplay from "../ProjectInfoDisplay";
import ProjectTransactions from "../ProjectTransactions";

interface Props {
  project: ProjectObject;
  onDeleteConfirmation: (id: number) => void;
  isDeleting: boolean;
}

const ProjectRow = ({ project, onDeleteConfirmation, isDeleting }: Props) => {
  const navigate = useNavigate();
  const createdTransaction = project.transactions?.find(
    ({ transactionType }) => transactionType === "createProject",
  );
  const lastBuildTransaction = getRecentPublishTransaction(
    project.transactions,
  );
  const buildStatus = useMemo(() => project.buildStatus, [project.buildStatus]);

  const handleDeleteClick = () => {
    onDeleteConfirmation(project.id);
  };

  const handleRowClick = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).closest("a, button") === null) {
      navigate(`/projects/${project.id}`);
    }
  };

  return (
    <Box
      onClick={handleRowClick}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      py={{ base: 3, md: 4 }}
      px={{ base: 4, md: 6 }}
      mb={{ base: 3, md: 4 }}
      boxShadow="sm"
      background="white"
      _hover={{
        textDecoration: "none",
        boxShadow: "md",
      }}
      cursor="pointer"
      role="group"
    >
      <Flex
        justify="space-between"
        align={{ base: "flex-start", md: "center" }}
        direction={{ base: "column", md: "row" }}
        gap="20px"
      >
        <Box>
          <Flex alignItems="center" gap={2}>
            <Heading
              fontWeight="bold"
              fontSize={18}
              _groupHover={{ textDecoration: "underline" }} // This line is correct now
            >
              {project.name}
            </Heading>
            {buildStatus && (
              <Badge
                bg={`${setStatusColor(buildStatus)}.50`}
                color={`${setStatusColor(buildStatus)}.600`}
                textTransform="capitalize"
                px={2}
                rounded="400px"
                fontWeight={500}
                borderColor={`${setStatusColor(buildStatus)}.300`}
                borderWidth="1px"
              >
                <Flex alignItems="center" gap={1}>
                  <Flex
                    width="6px"
                    height="6px"
                    borderRadius="50%"
                    bg={`${setStatusColor(buildStatus)}.600`}
                  />
                  {buildStatus === "pending" ? "Building" : buildStatus}
                </Flex>
              </Badge>
            )}
          </Flex>
          <Box color="gray.700" fontSize="sm">
            <ProjectInfoDisplay project={project} />
          </Box>
          {(project.customProjectUrls &&
            project.customProjectUrls.length > 0) ||
          project.hostedProjectUrl ? (
            <Flex alignItems="center" gap={1} justifyContent="left" my={1}>
              <Box w="18px" h="18px">
                <FiLink size={16} color="#637381" />
              </Box>
              {project.customProjectUrls?.map(({ url }, index) => (
                <ChakraLink
                  key={index}
                  href={`https://${url}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  display="inline-block"
                  width="100%"
                >
                  <Text
                    fontSize="sm"
                    isTruncated
                    maxW={{ base: "200px", sm: "300px", md: "full" }}
                  >
                    {url},
                  </Text>
                </ChakraLink>
              ))}
              {project.hostedProjectUrl && (
                <ChakraLink
                  href={`https://${project.hostedProjectUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  display="inline-block"
                  width="100%"
                >
                  <Text
                    fontSize="sm"
                    isTruncated
                    maxW={{ base: "200px", sm: "300px", md: "full" }}
                  >
                    {project.hostedProjectUrl}
                  </Text>
                </ChakraLink>
              )}
            </Flex>
          ) : null}
          <ProjectTransactions
            createdTransaction={createdTransaction}
            lastBuildTransaction={lastBuildTransaction}
          />
        </Box>
        <Flex onClick={(e) => e.preventDefault()}>
          {project.projectType === "upload" && (
            <Button
              as={RouterLink}
              to={`/projects/${project.id}/deploy`}
              leftIcon={<FiUpload />}
              colorScheme="gray"
              variant="outline"
              size="sm"
              mr={2}
            >
              Upload
            </Button>
          )}
          <Button
            leftIcon={<FiList />}
            colorScheme="gray"
            as={RouterLink}
            to={`/projects/${project.id}?tab=builds`}
            variant="outline"
            size="sm"
            mr={2}
          >
            Builds
          </Button>
          <Flex onClick={(e) => e.stopPropagation()}>
            {project.hasSuccessfulBuild && project.hostedProjectUrl && (
              <Button
                as="a"
                leftIcon={<FiEye />}
                colorScheme="gray"
                href={`https://${project.hostedProjectUrl}`}
                target="_blank"
                rel="noopener noreferrer"
                variant="outline"
                size="sm"
                mr={2}
              >
                View site
              </Button>
            )}
            <IconButton
              icon={<FiTrash />}
              colorScheme="gray"
              variant="outline"
              size="sm"
              aria-label="Delete"
              onClick={handleDeleteClick}
              isLoading={isDeleting}
              isDisabled={isDeleting}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ProjectRow;
