import { Text, Flex, Button, Box } from "@chakra-ui/react";
import { ProjectUpdatesProps } from "./BuildsTab.types";
import ProjectUpdateRow from "../../../components/ProjectUpdateRow";
import LoadingBuilds from "./LoadingBuilds";

function BuildsTab({
  projectType,
  projectUpdates,
  selectedPipelineId,
  loadingLogs,
  logs,
  isLoading,
  isMakingCurrentBuild,
  isTriggeringNewBuild,
  handleMakeCurrentBuild,
  handleTriggerNewBuild,
}: ProjectUpdatesProps) {
  if (isLoading) return <LoadingBuilds />;

  return (
    <Flex direction="column" align="flex-end" mt="5" width="100%">
      {projectType !== "upload" && (
        <Flex justifyContent="flex-end" mb="4">
          <Button
            onClick={handleTriggerNewBuild}
            variant="primary"
            fontWeight={500}
            isLoading={isTriggeringNewBuild}
            fontSize={{ base: "xs", md: "sm" }}
            p="3"
          >
            Trigger New Build
          </Button>
        </Flex>
      )}
      <Box w="100%">
        {!projectUpdates?.length ? (
          <Text>
            No Builds found. Builds are auto generated on new deployments.
          </Text>
        ) : (
          projectUpdates?.map((update) => (
            <ProjectUpdateRow
              key={update.id}
              projectUpdate={update}
              selectedPipelineId={selectedPipelineId}
              loadingLogs={loadingLogs}
              logs={logs}
              isMakingCurrentBuild={
                isMakingCurrentBuild || isTriggeringNewBuild
              }
              handleMakeCurrentBuild={handleMakeCurrentBuild}
            />
          ))
        )}
      </Box>
    </Flex>
  );
}

export default BuildsTab;
