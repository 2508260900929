import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  useToast,
  VStack,
  Text,
  Link,
  Flex,
  Spacer,
  Box,
  Input,
  Textarea,
  Badge,
  Card,
  CardBody,
  CardHeader,
  Heading,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spinner,
  Icon,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";
import { FaCheck } from "react-icons/fa";
import GithubAuthButton from "../GithubAuthButton";
import { createProject, updateProject, analyzeGithubRepo } from "../../api";
import LoadingStepper from "../LoadingStepper";
import FormHelpPopover from "../FormHelpPopover";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  encryptWithPublicKey,
  isProjectInfoGithub,
  isProjectInfoUrl,
  determineFrameworkType,
} from "../../util";
import { LOADING_STEPS_CREATE_PROJECT } from "../../constants";
import { useFeatureFlags } from "../../context/featureFlagsContext";

const initialState = {
  name: "",
  email: "",
  buildCommands: {
    buildCommand: "",
    buildDir: "",
    environmentVariables: "",
    outputDir: "",
    packageInstallCommand: "",
  },
  hostedProjectUrl: "",
  projectType: "",
  projectInfo: {
    repositoryId: "",
    repositoryFullName: "",
    branchName: "",
  },
} as ProjectObjectToCreate;

function ProjectConfigGithub({
  isEditing = false,
  projectData: projectDataInitial,
}: {
  isEditing?: boolean;
  projectData?: ProjectObject;
}) {
  const [hasGithubAuthentication, setHasGithubAuthentication] = useState(false);
  const [repositories, setRepositories] = useState<any[]>([]);
  const [selectedRepo, setSelectedRepo] = useState({} as any);
  const [isLoading, setIsLoading] = useState(true);
  const [projectCreating, setProjectCreating] = useState(false);
  const [projectData, setProjectData] = useState(
    projectDataInitial || initialState,
  );
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [framework, setFrameworkType] = useState("");
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isBuildInputsDisabled, setIsBuildInputsDisabled] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();

  const repoSectionRef = useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const buildDirRef = useRef<HTMLInputElement>(null);
  const outputDirRef = useRef<HTMLInputElement>(null);

  const isNextJs = framework === "nextjs";

  const { featureFlags } = useFeatureFlags();
  const isFrameworkEnabled = featureFlags?.IS_FRAMEWORK_ENABLED;

  useEffect(() => {
    if (!projectData || framework) return;
    const frameworkType = determineFrameworkType(
      projectData,
      isFrameworkEnabled || false,
    );
    setFrameworkType(frameworkType);
  }, [isFrameworkEnabled, projectData, framework]);

  useEffect(() => {
    const fetchRepositories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/github/repositories`,
          { credentials: "include" },
        );
        if (!response.ok) {
          throw new Error("Failed to fetch repositories");
        }
        const data = await response.json();
        setRepositories(data.repositories);
        setHasGithubAuthentication(true);
        setIsLoading(false);

        // Automatically select the single repository if there's only one
        if (data.repositories.length === 1) {
          setSelectedRepo(data.repositories[0]);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        setHasGithubAuthentication(false);
        setIsLoading(false);
      }
    };

    fetchRepositories();
  }, [toast]);

  useEffect(() => {
    if (isAnalyzing) {
      setIsBuildInputsDisabled(true);
      const timer = setTimeout(() => {
        setIsBuildInputsDisabled(false);
      }, 5000);
      return () => clearTimeout(timer);
    } else {
      setIsBuildInputsDisabled(false);
    }
  }, [isAnalyzing]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    if (projectData.buildCommands && name in projectData.buildCommands) {
      setProjectData((prev) => ({
        ...prev,
        buildCommands: { ...prev.buildCommands, [name]: value },
      }));
    } else if (projectData.projectInfo && name in projectData.projectInfo) {
      setProjectData((prev) => ({
        ...prev,
        projectInfo: { ...prev.projectInfo, [name]: value },
      }));
    } else {
      setProjectData((prev) => ({ ...prev, [name]: value }));
    }

    // Clear the error for the field being edited
    setFormErrors((prev) => {
      const { [name]: _, ...rest } = prev;
      return rest;
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { name, email, buildCommands, projectInfo } = projectData;
    const hasBranchName = isProjectInfoGithub(projectInfo);
    const updatedProjectInfo = {
      ...projectInfo,
      framework,
    };

    const errors: Record<string, string> = {};

    if (!name) errors.name = "Project name is required";
    if (!email) errors.email = "Contact email is required";
    if (!selectedRepo.id && hasBranchName && !projectInfo.repositoryId)
      errors.repository = "Repository is required";
    if (!framework) errors.framework = "Framework is required";
    if (isNextJs && !buildCommands.packageInstallCommand)
      errors.packageInstallCommand = "Package Install Command is required";
    if (isNextJs && !buildCommands.buildCommand)
      errors.buildCommand = "Build Command is required";
    if (isNextJs && !buildCommands.buildDir)
      errors.buildDir = "Build Directory is required";

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      toast({
        title: "Missing information",
        description: "Please fill all required fields before submitting.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      // Scroll to the first field with an error
      const firstErrorField = Object.keys(errors)[0];
      const refs = {
        name: nameRef,
        email: emailRef,
        repository: repoSectionRef,
        buildDir: buildDirRef,
        outputDir: outputDirRef,
      };

      const refToScroll = refs[firstErrorField as keyof typeof refs];
      if (refToScroll && refToScroll.current) {
        refToScroll.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }

      return false; // Explicitly return false to ensure the form doesn't submit
    }

    try {
      const isNextJsStatic = framework === "nextjs-static";
      let response = null;
      const encryptedEnvVars = encryptWithPublicKey(
        buildCommands.environmentVariables,
      );
      const payload = {
        name,
        email,
        buildCommands: {
          ...buildCommands,
          environmentVariables: encryptedEnvVars,
        },
        projectType: "github",
        projectInfo: hasBranchName
          ? {
              repositoryId: selectedRepo.id || projectInfo.repositoryId,
              repositoryFullName:
                selectedRepo.full_name || projectInfo.repositoryFullName,
              branchName: projectInfo.branchName,
              repositoryUrl: selectedRepo.html_url,
              framework: isNextJsStatic
                ? "static"
                : updatedProjectInfo.framework,
              frameworkUIDisplay: framework,
            }
          : updatedProjectInfo,
      };
      setProjectCreating(true);
      if (isEditing) {
        response = await updateProject(
          (projectData as ProjectObject).id,
          payload,
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to update project.");
        }

        toast({
          title: "Project Updated!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        return;
      }

      response = await createProject(payload);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create project.");
      }

      toast({
        title: "Project Created",
        description: "Project successfully created.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate("/projects");
    } catch (error) {
      const message =
        error instanceof Error ? error.message : "Failed to create project";

      toast({
        title: "Failed to create project",
        description: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setProjectCreating(false);
    }

    return false; // Ensure the form doesn't submit through the default HTML action
  };

  const analyzeRepo = async (repoFullName: string) => {
    if (!repoFullName) return;

    setIsAnalyzing(true);
    try {
      const { analysis } = await analyzeGithubRepo(
        `https://github.com/${repoFullName}`,
      );

      if (analysis.isFrontend) {
        // Auto-fill form fields based on analysis
        setProjectData((prev) => ({
          ...prev,
          buildCommands: {
            ...prev.buildCommands,
            buildDir: "",
            packageInstallCommand: analysis.buildCommands.install || "",
            buildCommand: "",
            outputDir: analysis.outputDir || "",
          },
        }));

        if (analysis.framework) {
          setFrameworkType(
            ["react", "vue", "angular"].includes(analysis.framework)
              ? "static"
              : analysis.framework,
          );
          setFormErrors((prev) => {
            const { framework: _, ...rest } = prev;
            return rest;
          });

          toast({
            title: "Repository Analysis Complete",
            description:
              "We've analyzed the repository and tried to fill out known configuration options. Please confirm and fill out any remaining config options.",
            status: "success",
            duration: 6000,
            isClosable: true,
          });
        } else {
          setFrameworkType("");
        }
      } else {
        setFrameworkType("");
        toast({
          title: "Non-Frontend Project Detected",
          description:
            "This repository doesn't appear to be a frontend project. You can still continue, but some features might not work as expected.",
          status: "warning",
          duration: 8000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Analysis Failed",
        description:
          error instanceof Error
            ? error.message
            : "Failed to analyze repository",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleRepoSelection = (repo: any) => {
    setSelectedRepo(repo);
    // Remove the repository error when a repo is selected
    setFormErrors((prev) => {
      const { repository, ...rest } = prev;
      return rest;
    });
    // Analyze the repository when selected
    analyzeRepo(repo.full_name);
  };

  if (isLoading)
    return (
      <Flex justifyContent="center">
        <Spinner thickness="4px" speed="0.65s" color="primary" size="xl" />
      </Flex>
    );
  if (!hasGithubAuthentication) {
    return (
      <VStack spacing={6} align="center" mt={10}>
        <GithubAuthButton
          Component={Button}
          props={{
            colorScheme: "teal",
            size: "lg",
            minWidth: "160px",
            variant: "primary",
          }}
          displayText="Connect to GitHub"
        />
      </VStack>
    );
  }

  return (
    <Box>
      <form onSubmit={handleSubmit} noValidate>
        <Flex
          marginTop={{ base: "20px", md: "40px" }}
          border="2x solid #eeeeee"
          width="100%"
        >
          <Flex
            backgroundColor="gray.50"
            borderRadius="5px"
            border="2px solid #eeeeee"
            width="100%"
          >
            <VStack
              spacing={{ base: 4, md: 6 }}
              backgroundColor="gray.50"
              padding={{ base: 3, md: 5 }}
              width="100%"
            >
              {!isEditing && (
                <>
                  <FormControl
                    isInvalid={!!formErrors.name}
                    isDisabled={isEditing}
                  >
                    <FormLabel
                      display={"inline-block"}
                      color={formErrors.name ? "red.500" : "inherit"}
                      fontSize={{ base: "sm", md: "md" }}
                    >
                      Project Name
                      <Text as="span" color="red.500" ml={1}>
                        *
                      </Text>
                    </FormLabel>
                    <Input
                      ref={nameRef}
                      backgroundColor="white"
                      name="name"
                      value={projectData.name}
                      onChange={handleInputChange}
                      placeholder="Enter project name"
                      fontSize={{ base: "sm", md: "md" }}
                      height={{ base: "32px", md: "40px" }}
                    />
                    <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                      {formErrors.name}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!formErrors.email}
                    isDisabled={isEditing}
                  >
                    <FormLabel
                      display="inline-block"
                      mr={1}
                      color={formErrors.email ? "red.500" : "inherit"}
                      fontSize={{ base: "sm", md: "md" }}
                    >
                      Contact Email
                      <Text as="span" color="red.500" ml={1}>
                        *
                      </Text>
                    </FormLabel>
                    <FormHelpPopover text="This email serves as a contact for the project in case node operators want to communicate with you or users want to get in touch to verify authenticity." />
                    <Input
                      ref={emailRef}
                      backgroundColor="white"
                      name="email"
                      value={projectData.email}
                      onChange={handleInputChange}
                      placeholder="Enter contact email"
                      fontSize={{ base: "sm", md: "md" }}
                      height={{ base: "32px", md: "40px" }}
                    />
                    <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                      {formErrors.email}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!formErrors.repository}
                    ref={repoSectionRef}
                  >
                    <Flex alignItems="center">
                      <Flex>
                        <FormLabel
                          display="inline"
                          mr={1}
                          color={formErrors.repository ? "red.500" : "inherit"}
                          fontSize={{ base: "sm", md: "md" }}
                        >
                          GitHub Repository
                          <Text as="span" color="red.500" ml={1}>
                            *
                          </Text>
                        </FormLabel>
                        <FormHelpPopover text="Only public repos supported for now" />
                      </Flex>
                      <Spacer />
                      <GithubAuthButton
                        Component={Link}
                        props={{
                          textDecor: "none",
                          fontSize: { base: "sm", md: "md" },
                          fontWeight: "500",
                        }}
                        displayText="+ Add More Repos"
                      />
                    </Flex>
                    <SimpleGrid
                      minChildWidth={"300px"}
                      spacing={4}
                      width={"100%"}
                      border={formErrors.repository ? "2px solid red" : "none"}
                      borderRadius="md"
                      p={formErrors.repository ? 2 : 0}
                    >
                      {repositories && repositories.length > 0 ? (
                        <>
                          {repositories.map((repo) => (
                            <Card
                              size="sm"
                              key={repo.id}
                              onClick={() => handleRepoSelection(repo)}
                              cursor="pointer"
                              border={
                                selectedRepo.id === repo.id
                                  ? "2px solid teal"
                                  : "none"
                              }
                              backgroundColor={
                                selectedRepo.id === repo.id
                                  ? "teal.50"
                                  : "white"
                              }
                              _hover={{
                                boxShadow: "lg",
                              }}
                            >
                              <CardHeader
                                py={{ base: 2, md: 3 }}
                                px={{ base: 3, md: 4 }}
                              >
                                <Flex alignItems="center">
                                  <Heading size={{ base: "xs", md: "sm" }}>
                                    {repo.name}
                                  </Heading>
                                  {selectedRepo.id === repo.id && (
                                    <Icon
                                      as={FaCheck}
                                      color="teal.500"
                                      ml={2}
                                      boxSize={{ base: 3, md: 4 }}
                                    />
                                  )}
                                </Flex>
                              </CardHeader>
                              <CardBody
                                py={{ base: 2, md: 3 }}
                                px={{ base: 3, md: 4 }}
                              >
                                <Box mt={{ base: 1, md: 2 }}>
                                  <Badge
                                    colorScheme={
                                      repo.visibility === "private"
                                        ? "gray"
                                        : "green"
                                    }
                                    fontSize={{ base: "xs", md: "sm" }}
                                  >
                                    {repo.visibility}
                                  </Badge>
                                  <Box float="right">
                                    <Link
                                      href={`https://github.com/${repo?.full_name}`}
                                      fontSize={{ base: "xs", md: "sm" }}
                                      display="inline"
                                      marginLeft={1}
                                      _hover={{ color: "teal" }}
                                      target="_blank"
                                    >
                                      <ExternalLinkIcon
                                        style={{ display: "inline" }}
                                        boxSize={{ base: 3, md: 4 }}
                                      />
                                      <Text display={"inline"} marginLeft={1}>
                                        Go To Repo
                                      </Text>
                                    </Link>
                                  </Box>
                                </Box>
                                {isAnalyzing && selectedRepo.id === repo.id && (
                                  <Box mt={4}>
                                    <Text fontSize="sm" color="gray.500">
                                      Analyzing repository...
                                    </Text>
                                  </Box>
                                )}
                              </CardBody>
                            </Card>
                          ))}
                        </>
                      ) : (
                        <Text fontSize={"x-large"}>
                          No repositories found. Please add more repos with the
                          button above.
                        </Text>
                      )}
                    </SimpleGrid>
                    <FormErrorMessage>{formErrors.repository}</FormErrorMessage>
                  </FormControl>
                </>
              )}
              <FormControl>
                <FormLabel
                  display={"inline-block"}
                  fontSize={{ base: "sm", md: "md" }}
                >
                  Branch Name
                </FormLabel>
                <FormHelpPopover text="Specify the branch to use for this project." />
                {(isProjectInfoUrl(projectData.projectInfo) ||
                  isProjectInfoGithub(projectData.projectInfo)) && (
                  <Input
                    backgroundColor="white"
                    name="branchName"
                    value={projectData.projectInfo.branchName}
                    onChange={handleInputChange}
                    placeholder="If not specified, uses default branch"
                    fontSize={{ base: "sm", md: "md" }}
                    height={{ base: "32px", md: "40px" }}
                  />
                )}
              </FormControl>

              {isFrameworkEnabled && (
                <FormControl
                  isInvalid={!!formErrors.framework}
                  isDisabled={isEditing || isBuildInputsDisabled}
                >
                  <FormLabel
                    display="inline-block"
                    color={formErrors.framework ? "red.500" : "inherit"}
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    Framework
                    <Text as="span" color="red.500" ml={1}>
                      *
                    </Text>
                  </FormLabel>
                  <FormHelpPopover text="Selecting a framework is necessary for building the application. The build process will differ if Next.js is selected." />
                  <Select
                    placeholder="Select framework"
                    value={framework}
                    onChange={(e) => {
                      setFrameworkType(e.target.value);
                      setFormErrors((prev) => {
                        const { framework: _, ...rest } = prev;
                        return rest;
                      });
                    }}
                    fontSize={{ base: "sm", md: "md" }}
                    height={{ base: "32px", md: "40px" }}
                    _placeholder={{ fontSize: { base: "sm", md: "md" } }}
                    backgroundColor="white"
                  >
                    <option value="nextjs">Next.js</option>
                    <option value="nextjs-static">
                      NextJS (Static export)
                    </option>
                    <option value="static">Static Site / ReactJS</option>
                  </Select>
                  <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                    {formErrors.framework}
                  </FormErrorMessage>
                </FormControl>
              )}
              <FormControl
                isInvalid={isNextJs && !projectData.buildCommands.buildDir}
                isDisabled={isBuildInputsDisabled}
              >
                <FormLabel
                  display="inline-block"
                  fontSize={{ base: "sm", md: "md" }}
                >
                  Build Directory
                  {isNextJs && (
                    <Text as="span" color="red.500" ml={1}>
                      *
                    </Text>
                  )}
                </FormLabel>
                <FormHelpPopover text="This is the directory the package install and build commands will be run in" />
                <Input
                  ref={buildDirRef}
                  backgroundColor="white"
                  name="buildDir"
                  value={projectData.buildCommands.buildDir}
                  onChange={handleInputChange}
                  placeholder="`.` or `./src`"
                  fontSize={{ base: "sm", md: "md" }}
                  height={{ base: "32px", md: "40px" }}
                />
                <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                  {isNextJs &&
                    !projectData.buildCommands.buildDir &&
                    "Build Directory is required"}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={
                  isNextJs && !projectData.buildCommands.packageInstallCommand
                }
                isDisabled={isBuildInputsDisabled}
              >
                <FormLabel
                  display={"inline-block"}
                  fontSize={{ base: "sm", md: "md" }}
                >
                  Package Install Command
                  {isNextJs && (
                    <Text as="span" color="red.500" ml={1}>
                      *
                    </Text>
                  )}
                </FormLabel>
                <FormHelpPopover text="This is the first command to run as part of the build process. Standard dependencies like Ubuntu, npm, yarn and pnpm are available by default but you can run custom commands or specify versions like npx pnpm@<version> install " />
                <Input
                  backgroundColor="white"
                  name="packageInstallCommand"
                  value={projectData.buildCommands.packageInstallCommand}
                  onChange={handleInputChange}
                  placeholder="npm install or yarn install"
                  fontSize={{ base: "sm", md: "md" }}
                  height={{ base: "32px", md: "40px" }}
                />
                <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                  {isNextJs &&
                    !projectData.buildCommands.packageInstallCommand &&
                    "Package Install Command is required"}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={isNextJs && !projectData.buildCommands.buildCommand}
                isDisabled={isBuildInputsDisabled}
              >
                <FormLabel
                  display={"inline-block"}
                  fontSize={{ base: "sm", md: "md" }}
                >
                  Build Command
                  {isNextJs && (
                    <Text as="span" color="red.500" ml={1}>
                      *
                    </Text>
                  )}
                </FormLabel>
                <FormHelpPopover text="This command runs after the Package Install Command and will initiate building static assets for your project. Basic Dependencies for Ubuntu, Node.js, NPM and more are supported by the build environment." />
                <Input
                  backgroundColor="white"
                  name="buildCommand"
                  value={projectData.buildCommands.buildCommand}
                  onChange={handleInputChange}
                  placeholder="npm run build"
                  fontSize={{ base: "sm", md: "md" }}
                  height={{ base: "32px", md: "40px" }}
                />
                <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                  {isNextJs &&
                    !projectData.buildCommands.buildCommand &&
                    "Build Command is required"}
                </FormErrorMessage>
              </FormControl>
              {framework !== "nextjs" && (
                <FormControl isDisabled={isBuildInputsDisabled}>
                  <FormLabel
                    display="inline-block"
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    Output Directory
                  </FormLabel>
                  <FormHelpPopover text="The directory where static assets are exported to. This is the path from the root directory, not from Build Directory and should contain an index.html at the root level." />
                  <Input
                    ref={outputDirRef}
                    backgroundColor="white"
                    name="outputDir"
                    value={projectData.buildCommands.outputDir}
                    onChange={handleInputChange}
                    placeholder="./build"
                    fontSize={{ base: "sm", md: "md" }}
                    height={{ base: "32px", md: "40px" }}
                  />
                </FormControl>
              )}
            </VStack>
          </Flex>
        </Flex>

        <Accordion
          allowMultiple
          marginTop={{ base: "20px", md: "40px" }}
          border="2x solid #eeeeee"
        >
          <AccordionItem
            backgroundColor="gray.50"
            borderRadius="5px"
            border="2px solid #eeeeee"
          >
            <AccordionButton backgroundColor={"gray.100"}>
              <Box flex="1" textAlign="left">
                <Heading
                  fontSize={{ base: "md", md: "xl" }}
                  as="h3"
                  fontWeight="semibold"
                >
                  Advanced
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <VStack spacing={6}>
                <FormControl>
                  <FormLabel
                    display={"inline-block"}
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    Environment Variables
                  </FormLabel>
                  <FormHelpPopover text="These will be injected into the build system when building the static assets. You can paste a .env file directly." />
                  <Textarea
                    backgroundColor="white"
                    name="environmentVariables"
                    value={projectData.buildCommands.environmentVariables}
                    onChange={handleInputChange}
                    placeholder="Enter environment variables"
                    rows={4}
                    fontSize={{ base: "sm", md: "md" }}
                  />
                </FormControl>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Button
          type="submit"
          variant="primary"
          mt={{ base: 3, md: 4 }}
          width="100%"
          isLoading={projectCreating}
          fontSize={{ base: "sm", md: "md" }}
        >
          {isEditing ? "Update Project" : "Create Project"}
        </Button>
      </form>
      {projectCreating && !isEditing && (
        <LoadingStepper steps={LOADING_STEPS_CREATE_PROJECT} />
      )}
    </Box>
  );
}

export default ProjectConfigGithub;
