import { useState } from "react";
import {
  Button,
  Box,
  Flex,
  Center,
  Text,
  Link,
  Heading,
  Divider,
  useBreakpointValue,
} from "@chakra-ui/react";
import ProjectConfigGithub from "../../components/ProjectConfigGithub";
import ProjectConfigUrl from "../../components/ProjectConfigUrl";
import ProjectConfigManual from "../../components/ProjectConfigManual";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { isInIframe } from "../../util";

function CreateProject() {
  const [projectType, setProjectType] = useState(
    isInIframe() ? "url" : "github",
  );
  const navigate = useNavigate();
  const dividerOrientation = useBreakpointValue<"horizontal" | "vertical">({
    base: "horizontal",
    md: "vertical",
  });

  const renderProjectComponent = () => {
    switch (projectType) {
      case "github":
        return <ProjectConfigGithub />;
      case "url":
        return <ProjectConfigUrl />;
      case "manual":
        return <ProjectConfigManual />;
      default:
        return;
    }
  };

  const renderProjectDescription = () => {
    switch (projectType) {
      case "github":
        return (
          <>
            <Text fontWeight="bold" color="green.500" mb={2}>
              This is the recommended option.
            </Text>
            {
              "Add a public GitHub repository that you own directly by authenticating with GitHub. Automatically updates your Earthfast site with each commit pushed to a specified branch and includes CI checks on Github."
            }
          </>
        );
      case "url":
        return "Add a public GitHub repository by URL. Use this if you don't want to authenticate your GitHub, or don't own the repo. Automatically updates your Earthfast site with each commit pushed to a specified branch.";
      case "manual":
        return (
          <>
            <Text fontWeight="bold" color="orange.500" mb={2}>
              Warning: Advanced Users Only!
            </Text>
            {
              "Manually update projects. There are two ways to manually upload content:"
            }
            <br />
            {
              "1. Upload a folder of built static assets from your frontend repo eg HTML, CSS, JS, images, etc."
            }
            <br />
            {"2. Use existing EarthFast assets built via the CLI ("}
            <Link
              color="blue"
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.armadanetwork.com/armada-network-docs/host-a-project-on-armada#h.gsecmjpmqpmt"
            >
              docs
            </Link>
            {")"}
          </>
        );
      default:
        return "";
    }
  };

  return (
    <Box
      px={{ base: 2, md: 5 }}
      py={{ base: 6, md: 12 }}
      borderWidth="1px"
      borderRadius="lg"
      width={{ base: "95%", md: "80%" }}
      marginX="auto"
      bg="white"
      position="relative"
    >
      <Button
        leftIcon={<ArrowBackIcon />}
        variant="ghost"
        onClick={() => navigate("/projects")}
        position="absolute"
        top={{ base: 2, md: 4 }}
        left={{ base: 2, md: 4 }}
        size={{ base: "sm", md: "md" }}
      >
        Back
      </Button>
      <Heading
        fontSize={{ base: "24px", md: "32px" }}
        as="h3"
        fontWeight="semibold"
        textAlign="center"
        paddingBottom={{ base: "16px", md: "24px" }}
        mt={{ base: 8, md: 8 }}
      >
        Project Setup
      </Heading>
      <Flex
        justify="space-around"
        mb={4}
        overflowX={{ base: "auto", md: "visible" }}
      >
        <Center
          borderWidth={1}
          borderColor="rgba(0, 0, 0, 0.24)"
          p={5}
          borderRadius={8}
          minWidth={{ base: "max-content", md: "auto" }}
          width={{ base: "95%", md: "auto" }}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box position="relative">
            <Button
              colorScheme="teal"
              borderRadius={0}
              variant="link"
              color={projectType === "github" ? "primary" : "#667085"}
              opacity={projectType === "github" ? 1 : 0.7}
              fontWeight={projectType === "github" ? "semibold" : "normal"}
              onClick={() => setProjectType("github")}
              sx={{
                "&:hover": {
                  textDecoration: "none",
                },
              }}
              width={{ base: "100%", md: "auto" }}
              isDisabled={isInIframe()}
            >
              Import by GitHub Auth
            </Button>
            {isInIframe() && (
              <Text
                color="black"
                fontSize="x-small"
                textAlign="center"
                display="block"
                position="absolute"
                bottom="50%"
                left="50%"
                transform="translateX(-50%) translateY(-65%)"
                width="85%"
                backgroundColor="lightblue"
                paddingX={2}
                borderRadius={4}
              >
                Only available on desktop
              </Text>
            )}
          </Box>
          <Divider
            orientation={dividerOrientation}
            height={{ base: "1px", md: "25px" }}
            width={{ base: "100%", md: "auto" }}
            marginX={{ base: 0, md: "12px" }}
            marginY={{ base: "8px", md: 0 }}
            bg="rgba(0, 0, 0, 0.24)"
          />
          <Button
            colorScheme="teal"
            borderRadius={0}
            variant="link"
            color={projectType === "url" ? "primary" : "#667085"}
            opacity={projectType === "url" ? 1 : 0.7}
            fontWeight={projectType === "url" ? "semibold" : "normal"}
            onClick={() => setProjectType("url")}
            sx={{
              "&:hover": {
                textDecoration: "none",
              },
            }}
            width={{ base: "100%", md: "auto" }}
            mb={{ base: 0, md: 0 }}
          >
            Import by GitHub URL
          </Button>
          <Divider
            orientation={dividerOrientation}
            height={{ base: "1px", md: "25px" }}
            width={{ base: "100%", md: "auto" }}
            marginX={{ base: 0, md: "12px" }}
            marginY={{ base: "8px", md: 0 }}
            bg="rgba(0, 0, 0, 0.24)"
          />
          <Button
            colorScheme="teal"
            borderRadius={0}
            variant="link"
            color={projectType === "manual" ? "primary" : "#667085"}
            opacity={projectType === "manual" ? 1 : 0.7}
            fontWeight={projectType === "manual" ? "semibold" : "normal"}
            onClick={() => setProjectType("manual")}
            sx={{
              "&:hover": {
                textDecoration: "none",
              },
            }}
            width={{ base: "100%", md: "auto" }}
            mb={{ base: 0, md: 0 }}
          >
            Manual Project Upload
          </Button>
        </Center>
      </Flex>
      <Center>
        <Box width={{ base: "85%", md: "60%" }} border="1px 1px solid black;">
          <Flex justify="center" mb={{ base: 2, md: 4 }}>
            <Text
              textAlign="center"
              color="#667085"
              opacity={0.7}
              fontSize={{ base: "14px", md: "16px" }}
            >
              {renderProjectDescription()}
            </Text>
          </Flex>
        </Box>
      </Center>
      <Center>
        <Box
          width={{ base: "95%", md: "70%" }}
          minWidth={{ base: "auto", md: "300px" }}
        >
          {renderProjectComponent()}
        </Box>
      </Center>
    </Box>
  );
}

export default CreateProject;
