import { Box, Heading, Text, Button, VStack, Flex } from "@chakra-ui/react";
import { FaExclamationTriangle } from "react-icons/fa";

function NotFound() {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      minW="100vw"
      bg="gray.50"
      px={4}
    >
      <VStack
        spacing={8}
        maxWidth={{ base: "90%", md: "60%" }}
        textAlign="center"
      >
        <Heading as="h1" size={{ base: "lg", md: "xl" }} fontWeight="extrabold">
          404: Page Not Found
        </Heading>
        <Text fontSize={{ base: "md", md: "lg" }}>
          It seems you've hit a block in the chain. The page you're looking for
          doesn't exist.
        </Text>
        <Box>
          <FaExclamationTriangle size="48" color="#FFA500" />
        </Box>
        <Button
          as="a"
          href="/"
          size="lg"
          variant="primary"
          fontWeight="semibold"
        >
          Return to Home
        </Button>
      </VStack>
    </Flex>
  );
}
export default NotFound;
