import { useState, useMemo, useRef, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { getRecentPublishTransaction } from "../../util";
import { deleteProject, getProject } from "../../api";
import ProjectView from "./ProjectView";

export enum TabNames {
  SETTINGS = "settings",
  BUILDS = "builds",
  NODES = "nodes",
  CUSTOM_DOMAINS = "custom-domains",
}

function getTabIndex(tab: string | null, isUploadType: boolean): number {
  const tabMap = isUploadType
    ? {
        [TabNames.BUILDS]: 0,
        [TabNames.NODES]: 1,
        [TabNames.CUSTOM_DOMAINS]: 2,
      }
    : {
        [TabNames.SETTINGS]: 0,
        [TabNames.BUILDS]: 1,
        [TabNames.NODES]: 2,
        [TabNames.CUSTOM_DOMAINS]: 3,
      };

  return tabMap[tab as TabNames] ?? 0;
}

function ProjectViewContainer() {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [projectData, setProjectData] = useState<ProjectObject | null>(null);
  const [isLoadingProject, setIsLoadingProject] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(() => {
    const tab = searchParams.get("tab")?.toLowerCase() ?? null;
    return getTabIndex(tab, false);
  });
  const createdTransaction = useMemo(
    () =>
      projectData?.transactions?.find(
        ({ transactionType }) => transactionType === "createProject",
      ),
    [projectData?.transactions],
  );
  const lastBuildTransaction = useMemo(
    () => getRecentPublishTransaction(projectData?.transactions),
    [projectData?.transactions],
  );
  const isBuilding = useMemo(
    () => !!projectData?.isBuilding,
    [projectData?.isBuilding],
  );
  const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (projectId) {
      setIsLoadingProject(true);
      getProject(Number(projectId))
        .then((data) => {
          setProjectData(data);
        })
        .catch(() => {
          toast({
            title: "Error",
            description: "Failed to fetch project data.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => {
          setIsLoadingProject(false);
        });
    }
  }, [projectId, toast]);

  useEffect(() => {
    if (projectData) {
      const tab = searchParams.get("tab")?.toLowerCase() ?? null;
      const isUploadType = projectData.projectType === "upload";
      setTabIndex(getTabIndex(tab, isUploadType));
    }
  }, [projectData, searchParams]);

  const handleDelete = async (id: number): Promise<void> => {
    setIsDeleting(true);
    try {
      const response = await deleteProject(id);
      if (response.ok) {
        navigate("/projects");
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Server error");
      }
    } catch (error) {
      toast({
        title: "Failed to delete project",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteClick = () => {
    setIsDeleteAlertOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setIsDeleteAlertOpen(false);
    if (projectData) {
      await handleDelete(projectData.id);
    }
  };

  const handleTabChange = (index: number) => {
    if (projectData) {
      const isUploadType = projectData.projectType === "upload";
      setTabIndex(index);
      const tabNames = isUploadType
        ? [TabNames.BUILDS, TabNames.NODES, TabNames.CUSTOM_DOMAINS]
        : [
            TabNames.SETTINGS,
            TabNames.BUILDS,
            TabNames.NODES,
            TabNames.CUSTOM_DOMAINS,
          ];
      setSearchParams({ tab: tabNames[index] });
    }
  };

  return (
    <ProjectView
      createdTransaction={createdTransaction}
      lastBuildTransaction={lastBuildTransaction}
      isBuilding={isBuilding}
      projectData={projectData}
      isDeleting={isDeleting}
      isLoadingProject={isLoadingProject}
      isDeleteAlertOpen={isDeleteAlertOpen}
      cancelRef={cancelRef}
      tabIndex={tabIndex}
      handleDeleteClick={handleDeleteClick}
      handleDeleteConfirm={handleDeleteConfirm}
      onCloseConfirm={() => setIsDeleteAlertOpen(false)}
      onGoBack={() => navigate("/projects")}
      setTabIndex={handleTabChange}
    />
  );
}

export default ProjectViewContainer;
