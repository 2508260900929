import { Text } from "@chakra-ui/react";
import TopAlertMessage from "../TopAlertBanner";

const validEnvironments = [
  "development",
  "testnet-sepolia-staging",
  "testnet-sepolia",
];

function StagingWarningBanner() {
  if (!validEnvironments.includes(process.env.REACT_APP_NODE_ENV || "")) {
    return null;
  }
  return process.env.REACT_APP_NODE_ENV === "testnet-sepolia-staging" ? (
    <TopAlertMessage
      id={"staging-node-reset-warning"}
      content={
        <Text
          color="white"
          fontSize={{ base: "sm", md: "md" }}
          textAlign="left"
          px={2}
        >
          Welcome to the EarthFast Staging Environment! Please note - projects
          will be reset every Wednesday evening.
        </Text>
      }
      expirationDays={7}
    />
  ) : null;
}

export default StagingWarningBanner;
