import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Flex, Text, Spinner, VStack } from "@chakra-ui/react";

type QueryParams = {
  code: string;
  state?: string;
  installationId?: string;
};

function useQuery() {
  const params = new URLSearchParams(useLocation().search);
  const paramsObj: QueryParams = { code: "", state: "", installationId: "" };
  if (params.has("code")) {
    paramsObj.code = params.get("code") as string;
  }
  if (params.has("state")) {
    paramsObj.state = params.get("state") as string;
  }
  if (params.has("installation_id")) {
    paramsObj.installationId = params.get("installation_id") as string;
  }

  return paramsObj;
}

function Callback() {
  const query = useQuery();
  const [mainMessage, setMainMessage] = useState("Connecting to GitHub...");
  const [subMessage, setSubMessage] = useState(
    "This will allow you to deploy your decentralized projects seamlessly 🚀",
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // This function will only be called once when the component mounts
    handleAuthorization();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // The empty dependency array ensures this effect runs only once

  const handleAuthorization = async () => {
    if (query.code && query.installationId) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/github/callback?code=${query.code}&installationId=${query.installationId}`,
          { credentials: "include" },
        );

        const data = await response.json();

        if (response.ok) {
          console.log("User created");
          window.location.href = "/projects/create";
        } else {
          console.error(
            "Authorization failed:",
            data.error_description || data.message,
          );
          setIsLoading(false);
          setMainMessage("Authorization failed");
          setSubMessage(
            `${data.error_description || data.message}. Please try again or contact support at contact@earthfast.com`,
          );
        }
      } catch (error) {
        console.error("Error authorizing Github app:", error);
        setIsLoading(false);
        setMainMessage("Connection Error");
        setSubMessage(
          "There was an error authorizing the Github app. Please try again or contact support at contact@earthfast.com",
        );
      }
    }
  };

  return (
    <Flex direction="column" align="center" justify="center" width="100%">
      <VStack spacing={4}>
        {isLoading && (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        )}
        <VStack spacing={2}>
          <Text as="h1" fontSize="2xl" fontWeight="bold" textAlign="center">
            {mainMessage}
          </Text>
          <Text fontSize="lg" textAlign="center" color="gray.600">
            {subMessage}
          </Text>
        </VStack>
      </VStack>
    </Flex>
  );
}

export default Callback;
