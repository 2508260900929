import { useState, useEffect, useRef } from "react";
import { useToast } from "@chakra-ui/react";
import {
  addCustomDomain as apiAddDomain,
  deleteCustomDomain,
} from "../../../api";
import CustomDomainsTab from "./CustomDomainsTab";
import { CustomDomainProps, DomainStatus } from "./CustomDomainsTab.types";
import {
  isApexDomain,
  getDnsInstructions,
  verifyDomainDNS,
} from "../../../util";

function CustomDomainsTabContainer({
  hostedProjectUrl,
  projectId,
  customProjectUrls = [],
}: CustomDomainProps) {
  const [customDomain, setCustomDomain] = useState("");
  const [domains, setDomains] = useState<DomainStatus[]>(() =>
    customProjectUrls.map((domain) => ({
      url: domain.url,
      status: domain.status as "pending" | "active" | "invalid",
      configured: domain.status === "active",
      type: domain.url.split(".").length === 2 ? "A" : "CNAME",
    })),
  );
  const [isAdding, setIsAdding] = useState(false);
  const [isDeletingDomain, setIsDeletingDomain] = useState<string | null>(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [domainToDelete, setDomainToDelete] = useState<string | null>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const toast = useToast();

  const handleDeleteClick = (domain: string) => {
    setDomainToDelete(domain);
    setIsDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setDomainToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (!domainToDelete) return;

    setIsDeleteDialogOpen(false);
    await handleDeleteDomain(domainToDelete);
    setDomainToDelete(null);
  };

  const handleDeleteDomain = async (domainUrl: string) => {
    setIsDeletingDomain(domainUrl);
    try {
      await deleteCustomDomain(projectId, domainUrl);
      setDomains(domains.filter((domain) => domain.url !== domainUrl));
      toast({
        title: "Domain removed",
        description: "Custom domain has been removed successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting domain:", error);
      toast({
        title: "Error removing domain",
        description:
          error instanceof Error
            ? error.message
            : "Failed to remove custom domain. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeletingDomain(null);
    }
  };

  const handleAddDomain = async () => {
    if (!customDomain) return;

    setIsAdding(true);

    try {
      const type = isApexDomain(customDomain) ? "A" : "CNAME";
      const checkResult = await verifyDomainDNS(customDomain, type);
      await apiAddDomain(projectId, customDomain);

      setDomains([
        ...domains,
        {
          url: customDomain,
          status: "pending",
          configured: checkResult.configured,
          type,
        },
      ]);
      setCustomDomain("");

      toast({
        title: "Domain added",
        description: checkResult.configured
          ? "Domain configuration is valid"
          : "Domain added. Please configure DNS records.",
        status: checkResult.configured ? "success" : "warning",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error adding domain:", error);
      toast({
        title: "Error adding domain",
        description:
          error instanceof Error
            ? error.message
            : "Failed to add custom domain. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsAdding(false);
    }
  };

  useEffect(() => {
    const checkConfigurations = async () => {
      const updatedDomains = [...domains];
      let hasChanges = false;

      for (const domain of updatedDomains) {
        const checkResult = await verifyDomainDNS(domain.url, domain.type);

        if (checkResult.configured !== domain.configured) {
          domain.configured = checkResult.configured;
          domain.status = checkResult.configured ? "active" : "pending";
          hasChanges = true;
        }
      }

      if (hasChanges) {
        setDomains(updatedDomains);
      }
    };

    checkConfigurations();

    const interval = setInterval(checkConfigurations, 30000);
    return () => clearInterval(interval);
  }, [domains]);

  return (
    <CustomDomainsTab
      domains={domains}
      customDomain={customDomain}
      isAdding={isAdding}
      isDeletingDomain={isDeletingDomain}
      isDeleteDialogOpen={isDeleteDialogOpen}
      domainToDelete={domainToDelete}
      cancelRef={cancelRef}
      hostedProjectUrl={hostedProjectUrl}
      getDnsInstructions={getDnsInstructions}
      onCustomDomainChange={setCustomDomain}
      onAddDomain={handleAddDomain}
      onDeleteClick={handleDeleteClick}
      onCancelDelete={handleCancelDelete}
      onConfirmDelete={handleConfirmDelete}
    />
  );
}

export default CustomDomainsTabContainer;
