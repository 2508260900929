import { Text, Box, Link as ChakraLink, Tooltip } from "@chakra-ui/react";
import { format, formatDistanceToNow } from "date-fns";
import { FiHelpCircle } from "react-icons/fi";
import { BASE_HASH_URL } from "../../constants";

interface ProjectTransactionsProps {
  createdTransaction?: Transaction;
  lastBuildTransaction?: Transaction;
  fontSize?: string;
}

const ProjectTransactions = ({
  createdTransaction,
  lastBuildTransaction,
  fontSize = "smaller",
}: ProjectTransactionsProps) => {
  return (
    <Box mt={1}>
      {createdTransaction && (
        <Text
          color="gray.500"
          fontSize={fontSize}
          sx={{
            "@media screen and (max-width: 480px)": {
              fontSize: "xs",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            },
          }}
        >
          <Text as="span" fontWeight="semibold">
            Created:
          </Text>{" "}
          <Box
            as="span"
            sx={{
              "@media screen and (max-width: 480px)": {
                display: "flex",
                alignItems: "center",
                gap: 2,
                mt: 1,
              },
            }}
          >
            {format(new Date(createdTransaction.timestamp), "PPP")} -{" "}
            <ChakraLink
              href={`${BASE_HASH_URL}/${createdTransaction.transactionHash}`}
              target="_blank"
            >
              <Text as="span" textDecoration="underline">
                Transaction
              </Text>
            </ChakraLink>{" "}
            <Tooltip
              label="Contracts have not been verified yet"
              fontSize="xs"
              placement="right"
            >
              <Box
                as="span"
                display="inline-block"
                verticalAlign="middle"
                bottom={0.3}
                position="relative"
              >
                <FiHelpCircle size={12} />
              </Box>
            </Tooltip>
          </Box>
        </Text>
      )}
      {lastBuildTransaction && (
        <Text
          color="gray.500"
          fontSize={fontSize}
          sx={{
            "@media screen and (max-width: 480px)": {
              fontSize: "xs",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              mt: 2,
            },
          }}
        >
          <Text as="span" fontWeight="semibold">
            Last published:
          </Text>{" "}
          <Box
            as="span"
            sx={{
              "@media screen and (max-width: 480px)": {
                display: "flex",
                alignItems: "center",
                gap: 2,
                mt: 1,
              },
            }}
          >
            {formatDistanceToNow(new Date(lastBuildTransaction.timestamp), {
              addSuffix: true,
            })}{" "}
            <ChakraLink
              href={`${BASE_HASH_URL}/${lastBuildTransaction.transactionHash}`}
              target="_blank"
            >
              <Text as="span" textDecoration="underline">
                Transaction
              </Text>
            </ChakraLink>
            <Tooltip
              label="Contracts have not been verified yet"
              fontSize="xs"
              placement="right"
            >
              <Box
                as="span"
                display="inline-block"
                verticalAlign="middle"
                bottom={0.3}
                position="relative"
                ml={1}
              >
                <FiHelpCircle size={12} />
              </Box>
            </Tooltip>
          </Box>
        </Text>
      )}
    </Box>
  );
};

export default ProjectTransactions;
