import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  useToast,
  VStack,
  Box,
  Input,
  Textarea,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Flex,
  Text,
  FormErrorMessage,
  Select,
} from "@chakra-ui/react";
import { createProject, updateProject, analyzeGithubRepo } from "../../api";
import LoadingStepper from "../LoadingStepper";
import FormHelpPopover from "../FormHelpPopover";
import { determineFrameworkType, encryptWithPublicKey } from "../../util";
import { LOADING_STEPS_CREATE_PROJECT } from "../../constants";
import { useFeatureFlags } from "../../context/featureFlagsContext";

const initialState = {
  name: "",
  email: "",
  buildCommands: {
    buildCommand: "",
    buildDir: "",
    environmentVariables: "",
    outputDir: "",
    packageInstallCommand: "",
  },
  hostedProjectUrl: "",
  projectType: "",
  projectInfo: {
    url: "",
    branchName: "",
    framework: "static",
  } as ProjectInfoUrl,
} as ProjectObjectToCreate;

const ProjectConfigUrl = ({
  isEditing = false,
  projectData: projectDataInitial,
}: {
  isEditing?: boolean;
  projectData?: ProjectObject;
}) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [projectCreating, setProjectCreating] = useState(false);
  const [projectData, setProjectData] = useState(
    projectDataInitial || initialState,
  );
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [framework, setFrameworkType] = useState("");
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isBuildInputsDisabled, setIsBuildInputsDisabled] = useState(false);

  // Refs for scrolling to error fields
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const urlRef = useRef<HTMLInputElement>(null);
  const buildDirRef = useRef<HTMLInputElement>(null);
  const outputDirRef = useRef<HTMLInputElement>(null);
  const frameworkRef = useRef<HTMLSelectElement>(null);

  const isNextJs = framework === "nextjs";

  const { featureFlags } = useFeatureFlags();
  const isFrameworkEnabled = featureFlags?.IS_FRAMEWORK_ENABLED;

  useEffect(() => {
    if (!projectData || framework) return;
    const frameworkType = determineFrameworkType(
      projectData,
      isFrameworkEnabled || false,
    );
    setFrameworkType(frameworkType);
  }, [isFrameworkEnabled, projectData, framework]);

  useEffect(() => {
    if (isAnalyzing) {
      setIsBuildInputsDisabled(true);
      const timer = setTimeout(() => {
        setIsBuildInputsDisabled(false);
      }, 5000);
      return () => clearTimeout(timer);
    } else {
      setIsBuildInputsDisabled(false);
    }
  }, [isAnalyzing]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { name, email, buildCommands } = projectData;
    const projectInfo = projectData.projectInfo as ProjectInfoUrl;

    const errors: Record<string, string> = {};
    if (!name) errors.name = "Project name is required";
    if (!email) errors.email = "Contact email is required";
    if (!projectInfo.url) errors.url = "Project URL is required";
    if (!framework) errors.framework = "Framework is required";
    if (isNextJs && !buildCommands.packageInstallCommand)
      errors.packageInstallCommand = "Package Install Command is required";
    if (isNextJs && !buildCommands.buildCommand)
      errors.buildCommand = "Build Command is required";
    if (isNextJs && !buildCommands.buildDir)
      errors.buildDir = "Build Directory is required";

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      toast({
        title: "Missing information",
        description: "Please fill all required fields before submitting.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      // Scroll to the first field with an error
      const firstErrorField = Object.keys(errors)[0];
      const refs = {
        name: nameRef,
        email: emailRef,
        url: urlRef,
        buildDir: buildDirRef,
        outputDir: outputDirRef,
        framework: frameworkRef,
      };

      const refToScroll = refs[firstErrorField as keyof typeof refs];
      if (refToScroll && refToScroll.current) {
        refToScroll.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }

      return;
    }

    try {
      let response = null;
      let trimmedUrl = projectInfo.url;
      const isNextJsStatic = framework === "nextjs-static";
      if (trimmedUrl.endsWith(".git")) {
        trimmedUrl = trimmedUrl.slice(0, -4);
      }
      const encryptedEnvironmentVariables = encryptWithPublicKey(
        buildCommands.environmentVariables,
      );
      const payload = {
        name,
        email,
        buildCommands: {
          ...buildCommands,
          environmentVariables: encryptedEnvironmentVariables,
        },
        projectType: "url",
        projectInfo: {
          url: trimmedUrl,
          branchName: projectInfo.branchName,
          domain: "github",
          framework: isNextJsStatic ? "static" : framework,
          frameworkUIDisplay: framework,
        },
      };
      setProjectCreating(true);
      if (isEditing) {
        response = await updateProject(
          (projectData as ProjectObject).id,
          payload,
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to update project.");
        }

        toast({
          title: "Project Updated!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        return;
      }

      response = await createProject(payload);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create project.");
      }

      toast({
        title: "Project Created",
        description: "Project successfully created.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate("/projects");
    } catch (error) {
      const message =
        error instanceof Error ? error.message : "Failed to create project";

      toast({
        title: "Failed to create project",
        description: message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setProjectCreating(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    if (projectData.buildCommands && name in projectData.buildCommands) {
      setProjectData((prev) => ({
        ...prev,
        buildCommands: { ...prev.buildCommands, [name]: value },
      }));
    } else if (projectData.projectInfo && name in projectData.projectInfo) {
      setProjectData((prev) => ({
        ...prev,
        projectInfo: { ...prev.projectInfo, [name]: value },
      }));
    } else {
      setProjectData((prev) => ({ ...prev, [name]: value }));
    }

    // Clear the error for the field being edited
    setFormErrors((prev) => {
      const { [name]: _, ...rest } = prev;
      return rest;
    });
  };

  const analyzeRepo = async (url: string) => {
    if (!url) return;

    setIsAnalyzing(true);
    try {
      const { analysis } = await analyzeGithubRepo(url);

      if (analysis.isFrontend) {
        // Auto-fill form fields based on analysis
        setProjectData((prev) => ({
          ...prev,
          buildCommands: {
            ...prev.buildCommands,
            buildDir: "",
            packageInstallCommand: analysis.buildCommands.install || "",
            buildCommand: "",
            outputDir: analysis.outputDir || "",
          },
        }));

        if (analysis.framework) {
          setFrameworkType(
            ["react", "vue", "angular"].includes(analysis.framework)
              ? "static"
              : analysis.framework,
          );
          setFormErrors((prev) => {
            const { framework: _, ...rest } = prev;
            return rest;
          });

          toast({
            title: "Repository Analysis Complete",
            description:
              "We've analyzed the repository and tried to fill out known configuration options. Please confirm and fill out any remaining config options.",
            status: "success",
            duration: 6000,
            isClosable: true,
          });
        } else {
          setFrameworkType("");
        }
      } else {
        setFrameworkType("");
        toast({
          title: "Non-Frontend Project Detected",
          description:
            "This repository doesn't appear to be a frontend project. You can still continue, but some features might not work as expected.",
          status: "warning",
          duration: 8000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Analysis Failed",
        description:
          error instanceof Error
            ? error.message
            : "Failed to analyze repository",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsAnalyzing(false);
    }
  };

  // Add this to your url input's onBlur handler
  const handleUrlBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const url = e.target.value;
    if (url) analyzeRepo(url);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit} noValidate>
        <Flex
          marginTop={{ base: "20px", md: "40px" }}
          border="2x solid #eeeeee"
          width="100%"
        >
          <Flex
            backgroundColor="gray.50"
            borderRadius="5px"
            border="2px solid #eeeeee"
            width="100%"
          >
            <VStack
              spacing={{ base: 4, md: 6 }}
              backgroundColor="gray.50"
              padding={{ base: 3, md: 5 }}
              width="100%"
            >
              {!isEditing && (
                <>
                  <FormControl
                    isInvalid={!!formErrors.name}
                    isDisabled={isEditing}
                  >
                    <FormLabel
                      display="inline-block"
                      color={formErrors.name ? "red.500" : "inherit"}
                      fontSize={{ base: "sm", md: "md" }}
                    >
                      Project Name
                      <Text as="span" color="red.500" ml={1}>
                        *
                      </Text>
                    </FormLabel>
                    <Input
                      ref={nameRef}
                      backgroundColor="white"
                      name="name"
                      value={projectData.name}
                      onChange={handleInputChange}
                      placeholder="Enter project name"
                      fontSize={{ base: "sm", md: "md" }}
                      height={{ base: "32px", md: "40px" }}
                      _placeholder={{ fontSize: { base: "sm", md: "md" } }}
                    />
                    <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                      {formErrors.name}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!formErrors.email}
                    isDisabled={isEditing}
                  >
                    <FormLabel
                      display="inline-block"
                      color={formErrors.email ? "red.500" : "inherit"}
                      fontSize={{ base: "sm", md: "md" }}
                    >
                      Contact Email
                      <Text as="span" color="red.500" ml={1}>
                        *
                      </Text>
                    </FormLabel>
                    <FormHelpPopover
                      text="This email serves as a contact for the project in case node operators want to communicate with you or users want to get in touch to verify authenticity."
                      isMobile={true}
                    />
                    <Input
                      ref={emailRef}
                      backgroundColor="white"
                      name="email"
                      value={projectData.email}
                      onChange={handleInputChange}
                      placeholder="Enter contact email"
                      fontSize={{ base: "sm", md: "md" }}
                      height={{ base: "32px", md: "40px" }}
                      _placeholder={{ fontSize: { base: "sm", md: "md" } }}
                    />
                    <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                      {formErrors.email}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!formErrors.url}
                    isDisabled={isEditing}
                  >
                    <FormLabel
                      display="inline-block"
                      color={formErrors.url ? "red.500" : "inherit"}
                      fontSize={{ base: "sm", md: "md" }}
                    >
                      Project Url
                      <Text as="span" color="red.500" ml={1}>
                        *
                      </Text>
                    </FormLabel>
                    <FormHelpPopover text="You can find the URL by clicking on the 'Code' button on the GitHub repository and copying the HTTPS URL."></FormHelpPopover>
                    <Input
                      ref={urlRef}
                      backgroundColor="white"
                      name="url"
                      value={(projectData.projectInfo as ProjectInfoUrl).url}
                      onChange={handleInputChange}
                      onBlur={handleUrlBlur}
                      placeholder="https://github.com/earthfast/demo-project"
                      fontSize={{ base: "sm", md: "md" }}
                      height={{ base: "32px", md: "40px" }}
                      isDisabled={isAnalyzing}
                      _placeholder={{ fontSize: { base: "sm", md: "md" } }}
                    />
                    {isAnalyzing && (
                      <Text fontSize="sm" color="gray.500">
                        Analyzing repository...
                      </Text>
                    )}
                    <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                      {formErrors.url}
                    </FormErrorMessage>
                  </FormControl>
                </>
              )}
              {isFrameworkEnabled && (
                <FormControl
                  isInvalid={!!formErrors.framework}
                  isDisabled={isEditing || isBuildInputsDisabled}
                >
                  <FormLabel
                    display="inline-block"
                    color={formErrors.framework ? "red.500" : "inherit"}
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    Framework
                    <Text as="span" color="red.500" ml={1}>
                      *
                    </Text>
                  </FormLabel>
                  <FormHelpPopover text="Selecting a framework is necessary for building the application. The build process will differ if Next.js is selected." />
                  <Select
                    ref={frameworkRef}
                    placeholder="Select framework"
                    value={framework}
                    onChange={(e) => {
                      setFrameworkType(e.target.value);
                      setFormErrors((prev) => {
                        const { framework: _, ...rest } = prev;
                        return rest;
                      });
                    }}
                    fontSize={{ base: "sm", md: "md" }}
                    height={{ base: "32px", md: "40px" }}
                    _placeholder={{ fontSize: { base: "sm", md: "md" } }}
                    backgroundColor="white"
                  >
                    <option value="nextjs">Next.js</option>
                    <option value="nextjs-static">
                      NextJS (Static export)
                    </option>
                    <option value="static">Static Site / ReactJS</option>
                  </Select>
                  <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                    {formErrors.framework}
                  </FormErrorMessage>
                </FormControl>
              )}
              <FormControl
                isInvalid={isNextJs && !projectData.buildCommands.buildDir}
                isDisabled={isBuildInputsDisabled}
              >
                <FormLabel
                  display="inline-block"
                  fontSize={{ base: "sm", md: "md" }}
                >
                  Build Directory
                  {isNextJs && (
                    <Text as="span" color="red.500" ml={1}>
                      *
                    </Text>
                  )}
                </FormLabel>
                <FormHelpPopover text="This is the directory the package install and build commands will be run in" />
                <Input
                  ref={buildDirRef}
                  backgroundColor="white"
                  name="buildDir"
                  value={projectData.buildCommands.buildDir}
                  onChange={handleInputChange}
                  placeholder="`.` or `./src`"
                  fontSize={{ base: "sm", md: "md" }}
                  height={{ base: "32px", md: "40px" }}
                />
                <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                  {isNextJs &&
                    !projectData.buildCommands.buildDir &&
                    "Build Directory is required"}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={
                  isNextJs && !projectData.buildCommands.packageInstallCommand
                }
                isDisabled={isBuildInputsDisabled}
              >
                <FormLabel
                  display="inline-block"
                  fontSize={{ base: "sm", md: "md" }}
                >
                  Package Install Command
                  {isNextJs && (
                    <Text as="span" color="red.500" ml={1}>
                      *
                    </Text>
                  )}
                </FormLabel>
                <FormHelpPopover text="This is the first command to run as part of the build process. Standard dependencies like Ubuntu, npm, yarn and pnpm are available by default but you can run custom commands or specify versions like npx pnpm@<version> install " />
                <Input
                  backgroundColor="white"
                  name="packageInstallCommand"
                  value={projectData.buildCommands.packageInstallCommand}
                  onChange={handleInputChange}
                  placeholder="npm install or yarn install"
                  fontSize={{ base: "sm", md: "md" }}
                  height={{ base: "32px", md: "40px" }}
                />
                <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                  {isNextJs &&
                    !projectData.buildCommands.packageInstallCommand &&
                    "Package Install Command is required"}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={isNextJs && !projectData.buildCommands.buildCommand}
                isDisabled={isBuildInputsDisabled}
              >
                <FormLabel
                  display="inline-block"
                  fontSize={{ base: "sm", md: "md" }}
                >
                  Build Command
                  {isNextJs && (
                    <Text as="span" color="red.500" ml={1}>
                      *
                    </Text>
                  )}
                </FormLabel>
                <FormHelpPopover text="This command runs after the Package Install Command and will initiate building static assets for your project. Basic Dependencies for Ubuntu, Node.js, NPM and more are supported by the build environment." />
                <Input
                  backgroundColor="white"
                  name="buildCommand"
                  value={projectData.buildCommands.buildCommand}
                  onChange={handleInputChange}
                  placeholder="npm run build"
                  fontSize={{ base: "sm", md: "md" }}
                  height={{ base: "32px", md: "40px" }}
                />
                <FormErrorMessage fontSize={{ base: "xs", md: "sm" }}>
                  {isNextJs &&
                    !projectData.buildCommands.buildCommand &&
                    "Build Command is required"}
                </FormErrorMessage>
              </FormControl>
              {framework !== "nextjs" && (
                <FormControl isDisabled={isBuildInputsDisabled}>
                  <FormLabel display="inline-block">Output Directory</FormLabel>
                  <FormHelpPopover text="The directory where static assets are exported to. This is the path from the root directory, not from Build Directory and should contain an index.html at the root level." />
                  <Input
                    ref={outputDirRef}
                    backgroundColor="white"
                    name="outputDir"
                    value={projectData.buildCommands.outputDir}
                    onChange={handleInputChange}
                    placeholder="./build"
                    fontSize={{ base: "sm", md: "md" }}
                    height={{ base: "32px", md: "40px" }}
                    _placeholder={{ fontSize: { base: "sm", md: "md" } }}
                  />
                </FormControl>
              )}
            </VStack>
          </Flex>
        </Flex>
        <Accordion
          allowMultiple
          marginTop={{ base: "20px", md: "40px" }}
          border="2x solid #eeeeee"
        >
          <AccordionItem
            backgroundColor="gray.50"
            borderRadius="5px"
            border="2px solid #eeeeee"
          >
            <AccordionButton backgroundColor={"gray.100"}>
              <Box flex="1" textAlign="left">
                <Heading
                  fontSize={{ base: "md", md: "xl" }}
                  as="h3"
                  fontWeight="semibold"
                >
                  Advanced
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              <VStack spacing={6}>
                <FormControl>
                  <FormLabel
                    display="inline-block"
                    fontSize={{ base: "sm", md: "md" }}
                  >
                    Environment Variables
                  </FormLabel>
                  <FormHelpPopover text="These will be injected into the build system when building the static assets. You can paste a .env file directly." />
                  <Textarea
                    backgroundColor="white"
                    name="environmentVariables"
                    value={projectData.buildCommands.environmentVariables}
                    onChange={handleInputChange}
                    placeholder="Enter environment variables"
                    rows={4}
                    fontSize={{ base: "sm", md: "md" }}
                    _placeholder={{ fontSize: { base: "sm", md: "md" } }}
                  />
                </FormControl>
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Button
          type="submit"
          variant="primary"
          mt={{ base: 3, md: 4 }}
          width="100%"
          isLoading={projectCreating}
          fontSize={{ base: "sm", md: "md" }}
        >
          {isEditing ? "Update Project" : "Create Project"}
        </Button>
      </form>
      {projectCreating && !isEditing && (
        <LoadingStepper steps={LOADING_STEPS_CREATE_PROJECT} />
      )}
    </Box>
  );
};

export default ProjectConfigUrl;
