import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";

interface FormHelpPopoverProps {
  text: string;
  isMobile?: boolean;
}

const FormHelpPopover = ({ text, isMobile }: FormHelpPopoverProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [isMobileDevice] = useMediaQuery("(max-width: 768px)");

  const handleClick = (e: React.MouseEvent) => {
    if (isMobileDevice) {
      onToggle();
    }
  };

  return (
    <Popover
      isOpen={isMobileDevice ? isOpen : undefined}
      onClose={onClose}
      trigger={isMobileDevice ? "click" : "hover"}
      placement={isMobileDevice ? "bottom" : "right"}
    >
      <PopoverTrigger>
        <IconButton
          icon={<QuestionIcon />}
          variant="ghost"
          size="sm"
          aria-label="Help"
          onClick={handleClick}
          ml={0}
          minW="auto"
          p={0}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody fontSize="sm">{text}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default FormHelpPopover;
