import {
  Center,
  Divider,
  Flex,
  Image,
  Link,
  Text,
  Heading,
  Tooltip,
  IconButton,
  Show,
  Hide,
  useDisclosure,
} from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import { HiMenu } from "react-icons/hi";
import { IoMdLogOut, IoMdClose } from "react-icons/io";

import { useUser } from "../../context/UserContext";

function Header({ logoutFn }: { logoutFn: () => void }) {
  const { user } = useUser();
  const loggedIn = user && user.userId;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        backgroundColor="white"
        minWidth="max-content"
        alignItems="center"
        gap={{ base: "2", md: "4" }}
        paddingX={{ base: "12px", md: "20px" }}
        paddingY={{ base: "12px", md: "16px" }}
        marginBottom="20px"
        as="header"
        justifyContent="space-between"
        borderBottom="1px solid rgba(0, 0, 0, 0.12)"
        flexDirection="row"
      >
        <Flex
          alignItems="center"
          as="nav"
          width={{ base: "auto", md: "auto" }}
          justifyContent={{ base: "flex-start", md: "flex-start" }}
        >
          <Flex as={Link} href="/" alignItems="center">
            <Image
              src="/earthfast-logo.svg"
              height="24px"
              verticalAlign="center"
            />
            <Heading
              verticalAlign={"center"}
              textDecor="none"
              fontSize="lg"
              fontWeight="600"
              marginLeft="10px"
              as="h1"
            >
              EarthFast
            </Heading>
          </Flex>
          <Hide below="md">
            {!!loggedIn && (
              <>
                <Divider
                  orientation="vertical"
                  height="25px"
                  marginX="12px"
                  opacity={1}
                  bg="rgba(0, 0, 0, 0.24)"
                  width="1px"
                />
                <Link
                  textDecor="none"
                  fontSize="md"
                  fontWeight="400"
                  href="/projects"
                >
                  Projects
                </Link>
              </>
            )}
          </Hide>
        </Flex>

        <Show below="md">
          <Flex gap={3} alignItems="center">
            {loggedIn && (
              <Flex alignItems="center" gap={2}>
                {!!user.github?.user?.avatar_url ? (
                  <Image
                    src={user.github?.user?.avatar_url}
                    borderRadius="50%"
                    height="30px"
                    border="1px solid rgba(0, 0, 0, 0.16)"
                  />
                ) : (
                  <Flex
                    width="30px"
                    height="30px"
                    rounded="50%"
                    bg="gray.300"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <FaUser size={14} color="white" />
                  </Flex>
                )}
                <Text fontSize="sm" color="#01131F">
                  {user.github?.user?.login
                    ? `@${user.github?.user?.login}`
                    : user.email}
                </Text>
              </Flex>
            )}
            <IconButton
              aria-label="Menu"
              icon={<HiMenu size={24} />}
              variant="ghost"
              onClick={onOpen}
              _hover={{ bg: "gray.100" }}
              _active={{ bg: "gray.200" }}
            />
          </Flex>
        </Show>

        <Hide below="md">
          <Flex
            alignItems="center"
            width={{ base: "auto", md: "auto" }}
            justifyContent={{ base: "flex-end", md: "flex-end" }}
          >
            {loggedIn ? (
              <Flex
                border="1px solid rgba(0, 0, 0, 0.16)"
                py="4px"
                pl="4px"
                pr="14px"
                borderRadius="100px"
              >
                {!!user.github?.user?.avatar_url ? (
                  <Image
                    src={user.github?.user?.avatar_url}
                    borderRadius="50%"
                    height="30px"
                    mr="5px"
                    border="1px solid rgba(0, 0, 0, 0.16)"
                  />
                ) : (
                  <Flex
                    width="30px"
                    height="30px"
                    rounded="50%"
                    bg="gray.300"
                    alignItems="center"
                    justifyContent="center"
                    mr="5px"
                  >
                    <FaUser size={14} color="white" />
                  </Flex>
                )}
                <Center>
                  <Tooltip
                    label={user.github?.user?.login ? user.email : undefined}
                    hasArrow
                  >
                    <Text fontSize="sm" color="#01131F">
                      {user.github?.user?.login
                        ? `@${user.github?.user?.login}`
                        : user.email}
                    </Text>
                  </Tooltip>
                  <Divider
                    orientation="vertical"
                    height="20px"
                    marginX="12px"
                    bg="rgba(0, 0, 0, 0.24)"
                    width="1px"
                  />
                  <Link onClick={logoutFn}>
                    <IoMdLogOut />
                  </Link>
                </Center>
              </Flex>
            ) : (
              <>
                <Link
                  textDecor="none"
                  fontSize="md"
                  fontWeight="500"
                  href="/login"
                >
                  Login
                </Link>
                <Divider
                  orientation="vertical"
                  height="20px"
                  marginX="12px"
                  opacity={1}
                />
                <Link
                  textDecor="none"
                  fontSize="md"
                  fontWeight="500"
                  href="/signup"
                  color="primary"
                >
                  Sign Up
                </Link>
              </>
            )}
          </Flex>
        </Hide>
      </Flex>

      <Show below="md">
        <Flex
          position="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="white"
          zIndex={20}
          flexDirection="column"
          visibility={isOpen ? "visible" : "hidden"}
          transform={isOpen ? "translateX(0)" : "translateX(100%)"}
          transition="all 0.3s ease-in-out"
          opacity={isOpen ? 1 : 0}
        >
          <Flex
            justify="space-between"
            align="center"
            paddingX={{ base: "12px", md: "20px" }}
            paddingY={{ base: "12px", md: "16px" }}
            borderBottom="1px solid rgba(0, 0, 0, 0.1)"
          >
            <Flex as={Link} href="/" align="center" onClick={onClose}>
              <Image
                src="/earthfast-logo.svg"
                height="24px"
                verticalAlign="center"
              />
              <Heading
                color="black"
                fontSize="lg"
                fontWeight="600"
                marginLeft="10px"
              >
                EarthFast
              </Heading>
            </Flex>
            <IconButton
              aria-label="Close menu"
              icon={<IoMdClose size={24} color="black" />}
              variant="ghost"
              onClick={onClose}
              _hover={{ bg: "gray.100" }}
              _active={{ bg: "gray.200" }}
            />
          </Flex>

          <Flex flexDirection="column" align="center" p={8} pt={16}>
            {loggedIn ? (
              <>
                <Link
                  href="/projects"
                  onClick={onClose}
                  color="gray.800"
                  fontSize="2xl"
                  fontWeight="500"
                  mb={6}
                  _hover={{ textDecoration: "none", color: "gray.600" }}
                >
                  Projects
                </Link>
                <Link
                  onClick={() => {
                    onClose();
                    logoutFn();
                  }}
                  color="gray.800"
                  fontSize="2xl"
                  fontWeight="500"
                  _hover={{ textDecoration: "none", color: "gray.600" }}
                >
                  Logout
                </Link>
              </>
            ) : (
              <>
                <Link
                  href="/login"
                  onClick={onClose}
                  color="gray.800"
                  fontSize="2xl"
                  fontWeight="500"
                  mb={6}
                  _hover={{ textDecoration: "none", color: "gray.600" }}
                >
                  Login
                </Link>
                <Link
                  href="/signup"
                  onClick={onClose}
                  color="primary"
                  fontSize="2xl"
                  fontWeight="500"
                  _hover={{ textDecoration: "none", opacity: 0.8 }}
                >
                  Sign Up
                </Link>
              </>
            )}
          </Flex>
        </Flex>
      </Show>
    </>
  );
}

export default Header;
