import {
  Box,
  Flex,
  Heading,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";
import AddProjectButtons from "../../components/AddProjectButtons";
import ProjectRow from "../../components/ProjectRow";
import Welcome from "../../components/Welcome";
import LoadingProjects from "../../components/LoadingProjects";

interface ProjectsProps {
  projects: ProjectObject[];
  isLoading: boolean;
  isAuthenticated: boolean;
  handleDeleteConfirmation: (id: number) => void;
  projectIdToDelete: number | null;
  handleConfirmDelete: () => Promise<void>;
  handleCancelDelete: () => void;
  isDeleting: boolean;
}

function Projects({
  projects,
  isLoading,
  isAuthenticated,
  handleDeleteConfirmation,
  projectIdToDelete,
  handleConfirmDelete,
  handleCancelDelete,
  isDeleting,
}: ProjectsProps) {
  const cancelRef = useRef<HTMLButtonElement>(null);

  if (isLoading) return <LoadingProjects />;
  if (!isAuthenticated) return <Welcome isAuthenticated={false} />;
  if (isAuthenticated && !projects.length) return <Welcome isAuthenticated />;

  return (
    <Flex
      direction="column"
      align="center"
      mt={{ base: 4, md: 10 }}
      width="100%"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        w={{ base: "95%", md: "80%" }}
        mb={{ base: 4, md: 8 }}
        gap={{ base: 3, sm: 0 }}
      >
        <Heading fontSize={{ base: 24, md: 32 }} fontWeight="semibold">
          My Projects
        </Heading>
        <AddProjectButtons />
      </Flex>
      <Box w={{ base: "95%", md: "80%" }}>
        {projects.map((proj) => (
          <ProjectRow
            key={proj.id}
            project={proj}
            onDeleteConfirmation={handleDeleteConfirmation}
            isDeleting={isDeleting && projectIdToDelete === proj.id}
          />
        ))}
      </Box>
      <AlertDialog
        isOpen={projectIdToDelete !== null}
        leastDestructiveRef={cancelRef}
        onClose={handleCancelDelete}
        size={{ base: "xs", md: "md" }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent mx={{ base: 4, md: 0 }}>
            <AlertDialogHeader
              fontSize={{ base: "md", md: "lg" }}
              fontWeight="bold"
            >
              Delete Project
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this project? This action cannot
              be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={handleCancelDelete}
                isDisabled={isDeleting}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleConfirmDelete}
                ml={3}
                isLoading={isDeleting}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
}

export default Projects;
