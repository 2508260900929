import { useToast } from "@chakra-ui/react";
import BuildsTab from "./BuildsTab";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getProjectUpdates,
  triggerBuild,
  makeCurrentBuild,
} from "../../../api";
import { Log } from "./BuildsTab.types";

interface BuildsTabContainerProps {
  projectData: ProjectObject | null;
}

function BuildsTabContainer({ projectData: project }: BuildsTabContainerProps) {
  const [projectUpdates, setProjectUpdates] = useState<ProjectUpdate[] | null>(
    null,
  );
  const [isFetchingProjectUpdates, setIsFetchingProjectUpdates] =
    useState(false);
  const [isMakingCurrentBuild, setIsMakingCurrentBuild] = useState(false);
  const [isTriggeringNewBuild, setIsTriggeringNewBuild] = useState(false);
  const [logs] = useState<Record<string, Log[]>>({});
  const [loadingLogs] = useState<Record<string, boolean>>({});
  const [selectedPipelineId] = useState<string | null>(null);
  const toast = useToast();

  const fetchProjectUpdates = useCallback(async () => {
    try {
      if (!project?.id) return;
      setIsFetchingProjectUpdates(true);
      const updatesData = await getProjectUpdates(project.id);
      const sortedUpdates = updatesData.sort((a, b) => b.id - a.id);
      setProjectUpdates(sortedUpdates);
    } catch (error) {
      console.error("Error fetching project details or updates:", error);
    } finally {
      setIsFetchingProjectUpdates(false);
    }
  }, [project]);

  useEffect(() => {
    fetchProjectUpdates();
  }, [fetchProjectUpdates]);

  useEffect(() => {
    const polling = async () => {
      try {
        if (
          !projectUpdates?.some(({ status }) => status === "pending") ||
          !project?.id
        ) {
          return null;
        }
        const updatedProjects = await getProjectUpdates(project.id);
        const hasBuildingProject = updatedProjects.some(
          ({ status }) => status === "pending",
        );

        if (!hasBuildingProject) {
          clearInterval(intervalId);
          const sortedUpdates = updatedProjects.sort((a, b) => b.id - a.id);
          setProjectUpdates(sortedUpdates);
        }
      } catch (error) {
        clearInterval(intervalId);
      }
    };
    const intervalId = setInterval(polling, 20000);

    return () => clearInterval(intervalId);
  }, [projectUpdates, project?.id]);

  const handleTriggerNewBuild = async () => {
    try {
      if (!project?.id) return;
      setIsTriggeringNewBuild(true);
      const newUpdate = await triggerBuild({ projectId: project.id });
      setProjectUpdates((prevUpdates) => [
        { ...newUpdate, status: "pending" },
        ...(prevUpdates || []),
      ]);
      toast({
        title: "New build triggered successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error triggering new build:", error);
      toast({
        title: "Error",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsTriggeringNewBuild(false);
    }
  };

  const handleMakeCurrentBuild = async (update: ProjectUpdate) => {
    try {
      if (!project?.id) return;
      setIsMakingCurrentBuild(true);
      const newUpdate = await makeCurrentBuild({
        projectId: project.id,
        bundleUrl: update.bundleUrl,
        checksum: update.checksum,
        projectUpdateId: update.id,
      });
      setProjectUpdates((prevUpdates) => [
        { ...newUpdate, status: "pending" },
        ...(prevUpdates || []),
      ]);

      toast({
        title: "Updated build successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating build:", error);
      toast({
        title: "Error",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsMakingCurrentBuild(false);
    }
  };

  const formattedProjectUpdates = useMemo(() => {
    if (!projectUpdates?.length) return null;
    const latestBuildId = projectUpdates?.reduce((latestId, build) => {
      return new Date(build.timestamp).getTime() >
        new Date(
          projectUpdates.find((b) => b.id === latestId)!.timestamp,
        ).getTime()
        ? build.id
        : latestId;
    }, projectUpdates[0].id);
    return projectUpdates.map((build) => ({
      ...build,
      isCurrentBuild: build.id === latestBuildId,
    }));
  }, [projectUpdates]);

  return (
    <BuildsTab
      projectType={project?.projectType}
      projectUpdates={formattedProjectUpdates}
      selectedPipelineId={selectedPipelineId}
      loadingLogs={loadingLogs}
      logs={logs}
      isLoading={isFetchingProjectUpdates}
      isMakingCurrentBuild={isMakingCurrentBuild}
      isTriggeringNewBuild={isTriggeringNewBuild}
      handleMakeCurrentBuild={handleMakeCurrentBuild}
      handleTriggerNewBuild={handleTriggerNewBuild}
    />
  );
}

export default BuildsTabContainer;
