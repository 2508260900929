import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function AddProjectButtons({ buttonProps }: any) {
  const navigate = useNavigate();

  const handleNavigateToCreateProject = () => {
    navigate("/projects/create");
  };

  return (
    <Button
      {...buttonProps}
      onClick={handleNavigateToCreateProject}
      variant="primary"
      fontSize={{ base: "xs", md: "md" }}
      px={{ base: 2, md: 4 }}
      py={{ base: 1, md: 2 }}
    >
      + Create Project
    </Button>
  );
}

export default AddProjectButtons;
