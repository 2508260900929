import ReactDOM from "react-dom/client";
import { initFavicon } from "./util";
import "./index.css";
import {
  ChakraProvider,
  extendTheme,
  StyleFunctionProps,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

import { UserProvider } from "./context/UserContext";
import { FeatureFlagProvider } from "./context/featureFlagsContext";
import App from "./components/App";

initFavicon();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const theme = extendTheme({
  config: {
    initialColorMode: "light",
  },
  styles: {
    global: {
      "h1, h2, h3, h4, h5, h6, label": {
        color: "secondary",
      },
      p: {
        color: "textColor",
      },
      body: {
        bg: "rgb(250, 250, 250)",
      },
    },
  },
  components: {
    Button: {
      variants: {
        primary: (props: StyleFunctionProps) => ({
          bg: mode("primary", "primary")(props),
          color: mode("white", "white")(props),
          _hover: {
            bg: mode("primaryDarker", "primaryDarker")(props),
          },
        }),
        primaryInverted: (props: StyleFunctionProps) => ({
          bg: mode("transparent", "transparent")(props),
          color: mode("primary", "primary")(props),
          borderColor: mode("primary", "primary")(props),
          borderWidth: "1px",
        }),
      },
    },
  },
  colors: {
    primary: "#2E93FF",
    primaryDarker: "#1C7FDB",
    secondary: "#002C53",
    textColor: "rgba(0, 44, 83, 0.7)",
  },
});

root.render(
  <ChakraProvider
    theme={theme}
    toastOptions={{ defaultOptions: { position: "top" } }}
  >
    <UserProvider>
      <FeatureFlagProvider>
        <App />
      </FeatureFlagProvider>
    </UserProvider>
  </ChakraProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
