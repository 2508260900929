import {
  Button,
  VStack,
  Text,
  IconButton,
  Checkbox,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { FiPlus, FiMinus, FiSearch } from "react-icons/fi";
import { memo } from "react";
import NodesTable from "../../../components/NodesTable/NodesTable";
import NodesPanelContainer from "../../../components/NodesPanelContainer/NodesPanelContainer";

interface NodesProps {
  nodes: ContentNode[];
  availableNodes: ContentNode[];
  selectedAvailableNodes: string[];
  searchTerm: string;
  currentPage: number;
  totalPages: number;
  nodesPerPage: number;
  totalNodes: number;
  isLoading: boolean;
  isReserving: boolean;
  onAddNodes: () => void;
  onRemoveNode: (nodeId: string) => void;
  onSearchChange: (term: string) => void;
  onPageChange: (page: number) => void;
  onNodeSelection: (nodeId: string, isSelected: boolean) => void;
  deletingNodeId: string | null;
  maxNodesLimit: number;
  currentNodesCount: number;
  exceedLimit: boolean;
}

export default memo(function NodesTab({
  nodes,
  availableNodes,
  selectedAvailableNodes,
  searchTerm,
  currentPage,
  totalPages,
  nodesPerPage,
  totalNodes,
  isLoading,
  isReserving,
  deletingNodeId,
  onAddNodes,
  onRemoveNode,
  onSearchChange,
  onPageChange,
  onNodeSelection,
  maxNodesLimit,
  currentNodesCount,
  exceedLimit,
}: NodesProps) {
  const remainingNodes = exceedLimit
    ? Infinity
    : maxNodesLimit - currentNodesCount;

  return (
    <VStack spacing={4} align="stretch" h="full">
      <NodesPanelContainer
        title="Selected Nodes"
        badgeCount={nodes.length}
        badgeColor="blue"
        description={
          exceedLimit
            ? "These are the nodes currently assigned to your project. You can remove nodes by clicking the minus icon."
            : `You can add up to ${maxNodesLimit} nodes to your project. You have ${remainingNodes} slots remaining.`
        }
      >
        <NodesTable
          nodes={nodes}
          renderAction={(node) => (
            <IconButton
              aria-label="Remove node"
              icon={
                deletingNodeId === node.id ? <Spinner size="xs" /> : <FiMinus />
              }
              size={{ base: "xs", sm: "sm" }}
              colorScheme="red"
              variant="outline"
              onClick={() => onRemoveNode(node.id)}
              isDisabled={deletingNodeId === node.id}
            />
          )}
        />
      </NodesPanelContainer>
      <NodesPanelContainer
        title="Available Nodes"
        badgeCount={totalNodes}
        badgeColor="gray"
        description="Select nodes from the list below to add them to your project. You can use the search and filters to find specific nodes."
        headerAction={
          <Tooltip
            isDisabled={!(nodes.length > remainingNodes && !exceedLimit)}
            label={`You have reached your maximum limit of ${maxNodesLimit} nodes`}
            hasArrow
          >
            <Button
              colorScheme="blue"
              size={{ base: "xs", sm: "sm" }}
              leftIcon={isReserving ? <Spinner size="xs" /> : <FiPlus />}
              onClick={onAddNodes}
              isDisabled={
                selectedAvailableNodes.length === 0 ||
                isReserving ||
                (!exceedLimit && selectedAvailableNodes.length > remainingNodes)
              }
              isLoading={isReserving}
            >
              {isReserving
                ? "Adding..."
                : `Add ${selectedAvailableNodes.length} Selected`}
            </Button>
          </Tooltip>
        }
      >
        <HStack
          justify="space-between"
          mb={4}
          flexDir={{ base: "column", sm: "row" }}
          spacing={{ base: 2, sm: 3 }}
        >
          <HStack spacing={3} flex={1} w={{ base: "full", sm: "auto" }}>
            <InputGroup size={{ base: "xs", sm: "sm" }}>
              <InputLeftElement pointerEvents="none">
                <FiSearch color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Search by ID or hostname..."
                value={searchTerm}
                onChange={(e) => onSearchChange(e.target.value)}
              />
            </InputGroup>
          </HStack>
        </HStack>

        <NodesTable
          nodes={availableNodes}
          renderAction={(node) => (
            <Checkbox
              size={{ base: "sm", sm: "md" }}
              isChecked={selectedAvailableNodes.includes(node.id)}
              onChange={(e) => onNodeSelection(node.id, e.target.checked)}
              isDisabled={
                !exceedLimit &&
                !selectedAvailableNodes.includes(node.id) &&
                selectedAvailableNodes.length >= remainingNodes
              }
            />
          )}
          isLoading={isLoading}
          showProjectIds={false}
        />

        <HStack
          justify="space-between"
          mt={4}
          flexDir={{ base: "column", sm: "row" }}
          spacing={{ base: 2, sm: 3 }}
        >
          <Text fontSize={{ base: "xs", sm: "sm" }}>
            Showing{" "}
            {availableNodes.length > 0
              ? (currentPage - 1) * nodesPerPage + 1
              : 0}{" "}
            to {Math.min(currentPage * nodesPerPage, totalNodes)} of{" "}
            {totalNodes} nodes
          </Text>
          <HStack>
            <Button
              size={{ base: "xs", sm: "sm" }}
              onClick={() => onPageChange(currentPage - 1)}
              isDisabled={currentPage === 1 || isLoading}
            >
              Previous
            </Button>
            <Text fontSize={{ base: "xs", sm: "sm" }}>
              Page {currentPage} of {totalPages}
            </Text>
            <Button
              size={{ base: "xs", sm: "sm" }}
              onClick={() => onPageChange(currentPage + 1)}
              isDisabled={currentPage >= totalPages || isLoading}
            >
              Next
            </Button>
          </HStack>
        </HStack>
      </NodesPanelContainer>
    </VStack>
  );
});
